import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const FOLLOW = 1;
const COMMENT = 2;
const MESSAGE = 4;
const VOTE = 8;
const CREATE = 16;
const UPDATE = 32;
const DELETE = 64;
const VERSION = 128;
const ASSESSDETAIL = 256;
const ASSESSFINAL = 512;

export const matrixLight = {
  computed: {
    $getBitCreate() {
        return CREATE;
    }
  },
  methods: {
    $canAccessModule(module, action = "") {        
      if (!module.explicitAccessGrantRequired) {
        return true;
      } else if (module.explicitAccessGrantBitmaskActive) {
        const token = JwtService.decodeToken();

        if (token.accessP2MStatus.length > 0) {
          const accessP2MStatus = token.accessP2MStatus.find(
            (x) => x.p2mId === module.id
          );
          if (accessP2MStatus) {
            const bitmask = accessP2MStatus?.bitmask || 0;
            // console.log("bitmask", bitmask);
            // console.log("action", action);
            switch (action) {
              case "follow":
                return (FOLLOW & bitmask) === 1;

              case "comment":
                return (COMMENT & bitmask) === 2;

              case "message":
                return (MESSAGE & bitmask) === 4;

              case "vote":
                return (VOTE & bitmask) === 8;

              case "create":
                return (CREATE & bitmask) === 16;

              case "update":
                return (UPDATE & bitmask) === 32;

              case "delete":
                return (DELETE & bitmask) === 64;

              case "version":
                return (VERSION & bitmask) === 128;

              case "assessDetail":
                return (ASSESSDETAIL & bitmask) === 256;

              case "assessFinal":
                return (ASSESSFINAL & bitmask) === 512;

              default:
                return false;
            }
          }
        }

        return false;
      } else {
        const token = JwtService.decodeToken();
        let user_enabled = 0;

        if (token.accessP2MStatus.length > 0) {
          token.accessP2MStatus.forEach((value) => {
            if (value.p2mId == module.id) {
              user_enabled = value.status == "GRANT" ? 1 : -1;
            }
          });
        }

        return user_enabled === 1;
      }
    },
    async $moduleActionAllowed(p2mId, actionsBitmask) {
      try {
        const endpoint = this.generateUrl(`public/users/actions-allowed-for-p2m-against-req-matrix-light/${p2mId}/${actionsBitmask}`, false);
        //generateUrl is done
        const res = await ApiService.get(this.$apiResource, endpoint);
        return res?.data || false;
      } catch(e) {
        console.log(e);
      }
    },
  },
};
