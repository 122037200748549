var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom zoom ribbon ribbon-clip ribbon-right"},[_c('div',{staticClass:"card-body py-5 ribbon ribbon-top ribbon-ver"},[(_vm.idIdeaRouter)?_c('router-link',{attrs:{"to":{
            name: 'processModuleIdeaDetail',
            query: {
              forced_evaluate: true
            },
            params: {
              id: _vm.idIdeaRouter,
              process_id: _vm.process_id,
              phase_id: _vm.phase_id,
              module_id: _vm.module_id,
              phase_status_id: _vm.phase_status_id
              }
            }}},[(_vm.publishAssessmentsMaster && _vm.ideaSimpleAssessmentStatusId > 1)?_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"bottom":"20px"}},[_c('span',{staticClass:"ribbon-inner",style:('color:#fff; background-color:' + _vm.ideaSimpleAssessmentStatusColor)}),_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(_vm._s(_vm.assessment))])]):_vm._e()]):_vm._e(),((this.idea.versionNumber && this.idea.versionNumber > 0) && this.module && this.module.activeVersioning)?_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"top":"12px"}},[_c('span',{staticClass:"ribbon-inner bg-primary"}),_c('span',{staticClass:"text-uppercase font-weight-bolder h3"},[_vm._v(_vm._s(this.idea.versionNumber))])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-start symbol symbol-50 symbol-lg-120 mr-5",staticStyle:{"width":"50%","max-width":"150px","max-height":"150px"}},[_c('div',{staticClass:"w-100 h-100",style:('background: url(' + (_vm.idea.logo ? _vm.idea.logo.uri : '/assets/media/bipart/idea-default.svg') + ') no-repeat center; background-size: cover;')})]),_c('div',{staticClass:"align-items-center"},[_c('div',{staticClass:"mt-2 d-flex flex-start"},[_c('div',{staticClass:"mr-3"},[(_vm.idIdeaRouter)?_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":{
              name: 'processModuleIdeaDetail',
              params: {
                id: _vm.idIdeaRouter,
                process_id: _vm.process_id,
                phase_id: _vm.phase_id,
                module_id: _vm.module_id,
                phase_status_id: _vm.phase_status_id
                }
              }}},[_c('div',{staticClass:"d-flex align-items-center text-dark text-primary font-size-h3 font-weight-bold mr-3"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.idea.title,50, '...'))+" "),(_vm.idea.ideaStatus.id == 2)?_c('span',{staticClass:"label label-lg label-pill label-inline mr-2",class:{
                    'label-light-warning': _vm.idea.ideaStatus.id != 3,
                    'label-light-primary': _vm.idea.ideaStatus.id == 3
                  }},[_vm._v(_vm._s(_vm.idea.ideaStatus.name))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5 lead"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.idea.description,90, '...')))]),_c('div',{staticClass:"d-flex flex-wrap my-2 font-size-h6"},[(_vm.idea.budgetMin)?_c('span',{staticClass:"text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"},[_c('i',{staticClass:"fas fa-coins mr-3 text-primary"}),_vm._v(" "+_vm._s(_vm.idea.budgetMin)+" € ")]):_vm._e(),(_vm.displayViewMap)?_c('span',{staticClass:"text-muted text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"},[_c('i',{staticClass:"fas fa-map-marked-alt text-primary"})]):_vm._e()])],1)]),_c('div',{staticClass:"flex-end d-flex align-items-center mt-lg-0 mt-3"},[(this.module && this.module.activeCommenting)?_c('span',{staticClass:"p-2 mr-1 mt-2"},[_c('i',{staticClass:"fas fa-comment text-primary"}),_vm._v(" "+_vm._s(_vm.idea.countIdeaComments))]):_vm._e(),(_vm.$canFollow)?_c('div',[(_vm.idea.followedByAuthUser)?[_c('button',{staticClass:"btn btn-sm btn-default btn-text-primary mr-2 mt-2",on:{"click":function($event){return _vm.delFollower()}}},[_c('i',{staticClass:"fas fa-heart text-danger"}),_vm._v(_vm._s(_vm.idea.countFollowers)+" ")])]:[_c('button',{staticClass:"btn btn-sm btn-default btn-text-primary mr-2 mt-2",on:{"click":function($event){return _vm.addFollower()}}},[_c('i',{staticClass:"fas fa-heart text-primary"}),_vm._v(" "+_vm._s(_vm.idea.countFollowers)+" ")])]],2):(_vm.$activeFollowing)?_c('span',{staticClass:"mr-3 mt-2"},[_c('i',{staticClass:"fas fa-heart text-danger"}),_vm._v(" "+_vm._s(_vm.idea.countFollowers))]):_vm._e(),(_vm.phase.processVotes.length > 0 && _vm.modulesWithVotes.includes(_vm.module.id))?_c('div',{staticClass:"d-flex flex-row"},[(_vm.idIdeaRouter)?_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                    name: 'processModuleIdeaDetail',
                      params: {
                        id: _vm.idIdeaRouter,
                        process_id: _vm.process_id,
                        phase_id: _vm.phase_id,
                        module_id: _vm.module_id,
                        phase_status_id: _vm.phase_status_id
                        }
                    }}},[_c('span',{staticClass:"mt-1 btn btn-primary btn-block font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VOTA")))])]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-row"},[(_vm.idIdeaRouter)?_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                    name: 'processModuleIdeaDetail',
                      params: {
                        id: _vm.idIdeaRouter,
                        process_id: _vm.process_id,
                        phase_id: _vm.phase_id,
                        module_id: _vm.module_id,
                        phase_status_id: _vm.phase_status_id
                        }
                    }}},[_c('span',{staticClass:"mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VISUALIZZA")))])]):_vm._e()],1),(_vm.votedIdeaByUser)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"btn btn-success"},[_c('i',{staticClass:"fa fa-star text-white pr-0 pb-1",attrs:{"title":_vm.$t((this.$handleContentTypeLabel(this.moduleContentType) + 'LIST.IDEAVOTATA'))}})])]):_vm._e()])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }