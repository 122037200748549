<template>

  <!--:: TOOLS MOBILE-->
  <div class="d-block d-md-none mx-6">
    <div class="btn-group btn-group-justified" role="group" aria-label="...">

      <!-- Vote recap  -->
<!--      <div class="btn-group mr-2" role="group" id="kt_quick_panel_toggle" >-->
<!--        <router-link-->
<!--            v-slot="{ href, navigate }"-->
<!--            :to="{-->

<!--          }"-->
<!--        >-->
<!--          <button type="button" class="btn btn-primary"><i class="fas fa-star text-white pr-0 pb-1"></i></button>-->
<!--        </router-link>-->
<!--      </div>-->

      <!-- Torna al processo -->
      <div class="btn-group mr-2" role="group">
        <router-link
          :to="{
            name: 'processDetail',
            params: { id: this.id }
            }"
          >
          <button type="button" class="btn btn-primary"><i class="fas fa-home text-white pr-0 pb-1"></i></button>
        </router-link>
      </div>

        <!-- About -->
      <div class="btn-group mr-1" role="group" v-if="is_process_about">
        <router-link
            :to="{
          name: 'processAbout',
          params: { process_id: this.id }
          }"
        >
          <button type="button" class="btn btn-primary">
            <i class="fas fa-list text-white pr-0 pb-1"></i>
          </button>
        </router-link>
      </div>

      <!-- Le fasi -->
      <!--
      <div class="btn-group mr-2" role="group">
        <router-link
          :to="{
          name: 'processAbout',
          params: { process_id: this.id }
          }"
          >
            <button type="button" class="btn btn-primary"><i class="fas fa-info-circle text-white pr-0 pb-1"></i></button>
        </router-link>
      </div>
      -->

      <!-- Istruzioni -->
      <div class="btn-group mr-1" role="group" v-if="is_process_guidelines">
        <router-link
            :to="{
          name: 'processGuidelines',
          params: { process_id: this.id }
          }"
        >
          <button type="button" class="btn btn-primary"><i class="fas fa-book-open text-white pr-0 pb-1"></i></button>
        </router-link>
      </div>

      <!-- NArrazione -->
      <div class="btn-group mr-1" role="group" v-if="is_process_narration">
        <router-link
            :to="{
          name: 'processNarration',
          params: { process_id: this.id }
          }"
        >
          <button type="button" class="btn btn-primary">
            <i class="fas fa-info-circle text-white pr-0 pb-1"></i>
          </button>
        </router-link>
      </div>

      <!-- FAQ -->
      <div class="btn-group mr-1" role="group" v-if="is_process_faq">
        <router-link
          :to="{
          name: 'processFaq',
          params: { process_id: this.id }
          }"
          >
            <button type="button" class="btn btn-primary"> <i class="fas fa-question text-white pr-0 pb-1"></i></button>
        </router-link>
      </div>

      <!-- Challenge
      <div class="btn-group mr-1" role="group">
        <router-link
          :to="{
          name: 'processListChallenges',
          params: { process_id: process_id }
          }"
          >
            <button type="button" class="btn btn-primary"><i class="fas fa-flag text-white pr-0 pb-1"></i></button>
        </router-link>
      </div> -->

      <!-- Scenario
      <div class="btn-group mr-1" role="group">
        <router-link
          :to="{
          name: 'processListScenarios',
          params: { process_id: process_id }
          }"
          >
            <button type="button" class="btn btn-primary"><i class="fas fa-globe-europe text-white pr-0 pb-1"></i></button>
        </router-link>
      </div> -->

      <!-- Community
      <div class="btn-group mr-1" role="group">
        <router-link
          :to="{
          name: 'processListGroups',
          params: { process_id: id }
          }"
          >
            <button type="button" class="btn btn-primary"><i class="fas fa-users text-white pr-0 pb-1"></i></button>
        </router-link>
      </div>-->

      <!-- Assistenza -->
      <div  class="btn-group mr-1" role="group">
        <a class="btn btn-primary" @click.prevent="alertHelp" id="pop-help">
            <i class="far fa-life-ring text-white pr-0 pb-1"></i>
        </a>
<!--        <b-popover target="pop-help" triggers="hover">-->
<!--          <template v-slot:title>{{$t("PROCESSMENU.ASSISTENZA")}}</template>-->
<!--        </b-popover>-->
      </div>

    </div>
  </div>
</template>

<script>
import { alertMixin } from "@/mixins/alert";

export default {
  name: "ProcessMenuMobile",
  mixins: [alertMixin],
  props: ["process_id", "is_process_about", "is_process_faq", "is_process_narration", "is_process_guidelines"],
  data() {
    return {
      // current_process_id: this.$route.params.process_id,
      id: this.process_id
    };
  }
};
</script>

<style scoped></style>
