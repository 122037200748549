<template>
  <div>
    <b-modal
        id="modal-phone"
        ref="modalPhone"
        size="lg"
        centered hide-backdrop hide-footer content-class="shadow" ok-only no-stacking
        @show="checkUser"
        @hidden="resetForm"
    >
      <div class="row mt-10">
        <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHMOBILE.PARTECIPAPROCESSO")}}</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h4 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
           <div class="h4 text-primary">{{$t("ADVAUTHMOBILE.VERIFICA1")}}</div>
          <p class="font-weight-bolder">{{$t("ADVAUTHMOBILE.VERIFICA2")}} </p>
          <form class="form mt-5 mb-5">

            <div class="form-group mb-5">
              <div class="input-group align-items-center">
                <div class="input-group-prepend ">
                  <b-form-select
                      id="prefix"
                      v-model="$v.form.prefix.$model"
                      :state="validateState('prefix')"
                      :options="options"
                      :class="{
                        invalid: $v.form.prefix.$dirty && $v.form.prefix.$invalid
                      }"
                      class="rounded-0 border-0 shadow-0 bg-white"
                  ></b-form-select>
                </div>
                <input
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="text"
                    v-bind:placeholder="$t('ADVAUTHMOBILE.PH1')"
                    name="mobile"
                    id="mobile"
                    ref="mobile"
                    v-model="$v.form.mobile.$model"
                    :state="validateState('mobile')"
                    aria-describedby="username-live-feedback"
                    autocomplete="off"
                    :class="{
                      invalid: $v.form.mobile.$dirty && $v.form.mobile.$invalid
                    }"
                />
              </div>
              <template
                  v-if="$v.form.prefix.$dirty && $v.form.prefix.$invalid"
              >
                  <div v-if="!$v.form.prefix.required" class="error pt-2 w-100"
                  >{{$t("ADVAUTHMOBILE.PHONEERROR1")}}</div
                  >
              </template>
              <template
                  v-if="$v.form.mobile.$dirty && $v.form.mobile.$invalid"
              >
                  <div v-if="!$v.form.mobile.required" class="error w-100 pt-2"
                  >{{$t("ADVAUTHMOBILE.PHONEERROR2")}}</div
                  >
                <div v-if="!$v.form.mobile.mobileIsValid" class="error w-100 pt-2"
                >{{$t("ADVAUTHMOBILE.PHONEERROR3")}}</div
                >

              </template>

            </div>

            <div class="form-group mb-5 text-left">
              <label class="checkbox m-0">
                <input
                  type="checkbox"
                  name="term_and_conditions"
                  v-model="term_and_conditions" :disabled="isDisabledCheck"/>
                  <span></span>
                  <div v-html='$t("ADVAUTHMOBILE.TERMINIECONDIZIONI")'></div>
              </label>
              <div class="form-text text-muted text-center"></div>
            </div>
            <div class="form-group mb-5 text-left">
              <label class="checkbox m-0">
                <input type="checkbox" name="privacy" v-model="privacy" :disabled="isDisabledCheck"/>
                <span></span>
                  <div v-html='$t("ADVAUTHMOBILE.PRIVACY")'></div>
              </label>
              <div class="form-text text-muted text-center"></div>
            </div>

            <div v-if="this.process.guidelines"
                 class="form-group mb-5 text-left">
              <label class="checkbox m-0">
                <input type="checkbox" name="process_guidelines" v-model="process_guidelines" />
                <span></span>
                <div>
                  <span v-html='$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA1")'></span>
                  <a :href="'/process-guidelines/' + process.id" target="_blank">
                    {{$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA2")}}</a>
                </div>
              </label>
              <div class="form-text text-muted text-center"></div>
            </div>

            <div class="form-group d-flex flex-wrap">
              <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-authRole>{{$t("ADVAUTHMOBILE.INDIETRO")}}</b-button>
              <b-button
                  :disabled="isDisabled"
                  class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
                  @click.prevent="checkMobile"
                  id="mobile_submit"
                  ref="mobile_submit"
              >{{$t("ADVAUTHMOBILE.PROCEDI")}}
              </b-button>

            </div>

          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import {REFRESH_TOKEN, REGISTER_PROCESS_BY_MOBILE, ASSIGN_PROCESS_BY_MOBILE, SET_HEADER_TOKEN} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import {commonMixin} from "@/mixins/common";
import i18nService from "@/core/services/i18n.service";
import { LOGOUT } from "@/core/services/store/auth.module";

const mobileRegex = new RegExp('^[0-9\\s]{10,15}$');
// const mobileRegex = new RegExp('^((3[1-6][0-9]))(\\d{7})$');
const mobileIsValid = (value) => mobileRegex.test(value);

export default {
  name: "advMobileAuth",
  mixins: [validationMixin, commonMixin],
  props: ['process'],
  data() {
    return {
      isDisabledCheck: false,
      selected: null,
      term_and_conditions: false,
      privacy: false,
      options: [
        // { value: null, text: 'Prefisso' },
        { value: '+39', text: '+39' },
        // { value: '+40', text: '+40' },
      ],
      form: {
        prefix: '+39',
        mobile: ""
      },
      // // Solo in test
      // process: {
      //   id: 132
      // }
      process_guidelines: false
    };
  },
  computed: {
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
    isDisabled: function() {
      // return !this.privacy || !this.term_and_conditions;
      if( this.process.guidelines && this.process.guidelines != "") {
        return !this.privacy || !this.term_and_conditions || !this.form.mobile || !this.process_guidelines;
      } else {
        return !this.privacy || !this.term_and_conditions || !this.form.mobile;
      }
    }
  },
  mounted() {
    // Se non è l'utente anonimo recupero lo stato dei flag dell'utente
    if(this.currentUser.id !== 5 ) {
      this.privacy = this.currentUser.privacyPolicyAccepted;
      this.term_and_conditions = this.currentUser.termsConditionsAccepted;
    }
  },
  validations: {
    form: {
      prefix: {
        required
      },
      mobile: {
        required,
        mobileIsValid
      }
    },
  },
  methods: {
    checkUser() {
      // Se non è l'utente anonimo recupero lo stato dei flag dell'utente
      if(this.currentUser.id !== 5 ) {
        this.isDisabledCheck = true;
        this.privacy = this.currentUser.privacyPolicyAccepted;
        this.term_and_conditions = this.currentUser.termsConditionsAccepted;
      }
    },
    resetForm() {

      this.privacy = false;
      this.term_and_conditions = false;

      this.form = {
        prefix: null,
        mobile: ""
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    close() {
      this.$refs["modalPhone"].hide();
    },
    checkMobile() {
      this.onSubmit();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    manageMobileExists(alertMsg) {
      console.log('Gestione mobile exists');
      const swalWithBootstrapButtons = Swal.mixin({
        // customClass: {
        //   confirmButton: 'btn btn-info',
        //   cancelButton: 'btn btn-info'
        // },
        // buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.$t('ADVAUTHMOBILE.ATTENZIONE'),
        text: alertMsg,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: this.$t("ADVAUTHMOBILE.RECUPERAPASSWORD"),
        cancelButtonText: this.$t("ADVAUTHMOBILE.INSERISCINUOVONUMERO"),
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('Eseguo operazioni di recupero password');
          this.$emit('switchToPasswordRecovery');
          this.$refs['modalPhone'].hide();
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          console.log('Eseguo operazioni di inserimento altro numero');
        }
      })

    },

    async onSubmit() {

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["mobile_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // validazione del numero superata
      this.$refs["mobile"].classList.add("is-valid");

      //## Chiamata di registrazione del record utente
      // La chiamata è legata ai dati dell'utente che chiama:

      if(
        this.currentUser.authorities === "undefined" ||
        this.currentUser.authorities.includes('SCOUT'))
      { // se è un utente anonimo chiamo con post
        let registrationData = {
          organizationDomain: this.$currentDomain,
          processId: this.process.id,
          groupId: null,
          mobile: this.$v.form.prefix.$model + " " + this.$v.form.mobile.$model, //"+39 333 1234567",
          locale: i18nService.getActiveLanguage(),
          termsConditionsAccepted: this.term_and_conditions,
          privacyPolicyAccepted: this.privacy,
          // fakeSmsCode: "987654"
        };
        console.log("Mobile1: ", registrationData);

        await this.$store
          .dispatch(REGISTER_PROCESS_BY_MOBILE, registrationData)
          .then(response => {
            // console.log('Risposta dalla promise register in then: ', response);
            if (response.status == 200 || response.status == 201) {
              // console.log('Aggiorno il token');
              this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
              this.$store.dispatch(SET_HEADER_TOKEN)
              // console.log('Emetto evento');
              this.$emit('switchToMobileVerify');
              // console.log('Evento emesso')
              this.$refs['modalPhone'].hide();
              // console.log('Modale chiusa')
            } else if(response.status == 400) {
              this.manageAxiosErrors(response);
            } else {
              Swal.fire(
                  // this.$t("ADVAUTHMOBILE.ALERT1TITLE"), // #key_loc
                  // this.$t("ADVAUTHMOBILE.ALERT1") + response.status, // #key_loc
                "info"
              );
            }
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
          })
          .catch(err => {
            const [code, alertMsg ] = this.getDataError(err);

            if(code == '405') {
              this.manageMobileExists(alertMsg);
            } else {
              Swal.fire({
                title: this.$t('REGISTER.ATTENZIONE'),
                text: alertMsg,
                icon: "error",
                confirmButtonClass: "btn btn-secondary"
              });

            }

            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          });
      }
      else { // se è un utente registrato chiama la PUT
        let assignData = {
          id: this.currentUser.id,
          credentials: {
            organizationDomain: this.$currentDomain,
            processId: this.process.id,
            groupId: null,
            mobile: this.$v.form.prefix.$model + " " + this.$v.form.mobile.$model, //"+39 333 1234567",
            canOverride: true,
            locale: i18nService.getActiveLanguage(),
            // fakeSmsCode: "987654"
          }
        }
        console.log("Mobile2: ", assignData);

        await this.$store
          .dispatch(ASSIGN_PROCESS_BY_MOBILE, assignData)
          .then(response => {
            console.log('Risposta dalla promise register in then: ', response);
            if (response.status == 200) {
              this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
              this.$store.dispatch(SET_HEADER_TOKEN)
              this.$emit('switchToMobileVerify');
              this.$refs['modalPhone'].hide();
            } else if(response.status == 400) {
              this.manageAxiosErrors(response);
            } else {
              Swal.fire(
                  // this.$t("ADVAUTHMOBILE.ALERT2TITLE"),  // #key_loc
                  // this.$t("ADVAUTHMOBILE.ALERT2"), + response.status, // #key_loc
                "info"
              );
            }
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
          })
          .catch(err => {
            const [code, alertMsg ] = this.getDataError(err);

            if(code == '405') {
              this.manageMobileExists(alertMsg);
            } else {
              Swal.fire({
                title: this.$t('REGISTER.ATTENZIONE'),
                text: alertMsg,
                icon: "error",                
                showCancelButton: true,                
                cancelButtonText: this.$t("ADVAUTHMOBILE.EXIT"),
              }).then((result) => {
                if ( result.dismiss === Swal.DismissReason.cancel ) {
                  this.$store
                  .dispatch(LOGOUT)
                  .then(() => window.location.reload());
                }
              });
            }
              /*
              // console.log('Errore: ', err);
              let errMsg = err.data.message;
              let alertMsg = '';
              let code = 0;

              if (errMsg) {
                code = errMsg.split("|")[0].split(":")[1];
                console.log('ResponseId: ', code);
                alertMsg = this.$t('MESSAGEAUTH.' + code.toString())
              }

              if(code == '405') {
                this.manageMobileExists(alertMsg);
              } else {
                this.manageAxiosErrors(err);
              }
              */

            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          });
      }
    }
  }
}
</script>

<style scoped>
  .error{
    color:#f00;
  }
  .invalid {
    border-color: #f00;
  }
  select#prefix.custom-select.is-valid{
    background-image: none;
  }
</style>
