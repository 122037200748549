<template>
  <div class="card card-custom card-stretch border-primary-hover shadow-sm gutter-b zoom ribbon ribbon-clip ribbon-right">

    <!--Ribbon Assessment-->
    <router-link v-if="idIdeaRouter"
        :to="{
            name: 'processModuleIdeaDetail',
            query: {
              forced_evaluate: true
            },
            params: {
              id: idIdeaRouter,
              process_id: process_id,
              phase_id: phase_id,
              module_id: module_id,
              phase_status_id: phase_status_id
              }
            }"
    >
      <div class="ribbon-target p-3" style="top: 210px;" v-if="publishAssessmentsMaster && ideaSimpleAssessmentStatusId > 1">
          <span
              class="ribbon-inner"
              :style="'color:#fff; background-color:' + ideaSimpleAssessmentStatusColor"
          ></span>
        <span class=" text-uppercase font-weight-bolder">{{assessment}}</span>
      </div>
    </router-link>
    <!--end::Ribbon Assessment-->

    <!--Ribbon Versioning-->
    <div v-if="(this.idea.versionNumber && this.idea.versionNumber > 0) && this.module && this.module.activeVersioning" class="ribbon-target p-3" style="top: 12px;">
      <span class="ribbon-inner bg-primary"></span>
      <span class="text-uppercase font-weight-bolder h3">{{this.idea.versionNumber}}</span>
    </div>
    <!--end::Ribbon Versioning-->

    <div class="card-body ribbon ribbon-top ribbon-ver">

<!--      <div class="ribbon-target bg-warning" style="top: -2px; right: 45px;" v-if="votedIdea()">-->
<!--        <i class="fa fa-star text-white mr-3"></i> <span class="font-bolder">{{$t("PARTIALSIDEE.VOTATA")}}</span>-->
<!--      </div>-->
      <div class="thumbnail-idea">
        <router-link v-if="idIdeaRouter"
            class="cursor-pointer"
            :to="{
        name: 'processModuleIdeaDetail',
        params: {
          id: idIdeaRouter,
          process_id: process_id,
          phase_id: phase_id,
          module_id: module_id,
          phase_status_id: phase_status_id
          }
        }">
          <img
              :src="
              idea.logo
              ? idea.logo.uri
              : '/assets/media/bipart/idea-default.svg'
            "
              alt=""
              class="portait"
          />
        </router-link>
      </div>
      <h3 class="mt-5"> {{ idea.title | truncate(50, '...') }}</h3>
      <p class="mt-5">{{ idea.description | truncate(90, '...') }}</p>
      <span class="font-size-h5 font-weight-bold mr-6" v-if="idea.budgetMin">{{$t("PARTIALSIDEE.BUDGET")}} {{idea.budgetMin}}</span>
    </div>


    <div class="card-footer d-flex justify-content-end border-0 pt-0 px-5">

      <div v-if="this.module && this.module.activeCommenting" class="d-flex flex-column">
        <span class="p-2 mr-1 mt-2"><i class="fas fa-comment text-primary"></i> {{idea.countIdeaComments}}</span>
      </div>

      <div class="d-flex flex-column" v-if="$canFollow">
        <template v-if="idea.followedByAuthUser">
          <button @click="delFollower()" class="btn btn-sm btn-default btn-text-primary mr-2 mt-2">
            <i class="fas fa-heart text-danger"></i>
            <span class="pl-3">{{idea.countFollowers}}</span>
          </button>
        </template>
        <template v-else>
          <button @click="addFollower()" class="btn btn-sm btn-default btn-text-primary mr-2 mt-2">
            <i class="fas fa-heart text-primary"></i>
            <span class="pl-3">{{idea.countFollowers}}</span>
          </button>
        </template>
      </div>
      <span v-else-if="$activeFollowing" class="p-2 mr-1 mt-2"><i class="fas fa-heart text-danger"></i> {{idea.countFollowers}}</span>

      <!-- ID 2 - PUBLIC - VOTED -->
      <!-- ID 2 - PUBLIC - VOTED -->
      <div class="d-flex flex-column" v-if="phase.processVotes.length > 0 && modulesWithVotes.includes(module.id)">
        <router-link v-if="idIdeaRouter"
            :to="{
            name: 'processModuleIdeaDetail',
              params: {
                id: idIdeaRouter,
                process_id: process_id,
                phase_id: phase_id,
                module_id: module_id,
                phase_status_id: phase_status_id
                }
            }"
          class="mr-2"
        >
          <span class="mt-1 btn btn-primary btn-block font-weight-bolder text-uppercase" >{{$t("PARTIALSPROCESSI.VOTA")}}</span>
        </router-link>
      </div>
      <div class="d-flex flex-column" v-else>
        <router-link v-if="idIdeaRouter"
            :to="{
            name: 'processModuleIdeaDetail',
              params: {
                id: idIdeaRouter,
                process_id: process_id,
                phase_id: phase_id,
                module_id: module_id,
                phase_status_id: phase_status_id
                }
            }"
          class="mr-2"
        >
          <span class="mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase" >{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</span>
        </router-link>
      </div>
       <div class="d-flex flex-column"  v-if="votedIdeaByUser">
         <div class="btn btn-success"> <i class="fa fa-star text-white pr-0 pb-1"></i></div>
       </div>


    </div>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "GridIdeaCard",
  props: ["idea", "process_id", "phase", "phase_id", "phase_status_id", "module_id", "module"],
  data() {
    return {
      idIdeaRouter: null,
      assessment: '',
      ideaSimpleAssessmentStatusId: 1,
      ideaSimpleAssessmentStatusColor: '',
      publishAssessmentsMaster: false,
      modulesWithVotes: [],
      ongoingPrivateRoles : ['ASSESSOR', 'EXPERT', 'DELEGATE'],
      allAuthRoles: [
        'ADMIN', 'FACILITATOR', 'ASSESSOR', 'EXPERT', 'DELEGATE', 'AUTH_CITIZEN'
      ],
      adminRoles: [
        'ADMIN', 'FACILITATOR'
      ],
      evaluateRoles: [
        'ASSESSOR', 'EXPERT'
      ]
    }
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]),

    votedIdeaByUser() {
      return (this.idea.type1VotedByAuthUser || this.idea.type4VotedByAuthUser > 0);
    }

  },
  async mounted(){
    this.setModulesWithVotes(this.phase);

    if(this.idea && this.module) {

      this.publishAssessmentsMaster = this.module.publishAssessmentsMaster || (this.idea && this.idea.assessor && this.idea.assessor.id && this.idea.assessor.id == this.currentUser.id);

      this.ideaSimpleAssessmentStatusColor = this.idea.ideaSimpleAssessmentStatus.color ?? '';
      this.ideaSimpleAssessmentStatusId = this.idea.ideaSimpleAssessmentStatus.id ?? 1;
      this.assessment = this.idea.ideaSimpleAssessmentStatus.localizations[0].name ?? '';

      this.idIdeaRouter = this.idea.id;
    }

    if (this.idea && this.idea.existsUnpubVersionInGroup == true && this.idea.author.id == this.currentUser.id && this.module && this.module.canVersion) {

      let qs = "?versionGroup.equals=" + this.idea.versionGroup + "&ideaStatusId.equals=1";

      let endpoint = this.generateUrl('ideas', false);
      //generateUrl is done
      await ApiService.get(this.$apiResourceOpen, endpoint + qs)
      .then(res => {

        if(res.data && res.data[0]) {

          this.idIdeaRouter = res.data[0].id;
        }
      })
    }
  },
  methods: {
    addFollower() {
      this.$emit("incFollower", this.idea.id);
    },
    delFollower() {
      this.$emit("decFollower", this.idea.id);
    },
    setModulesWithVotes (phase) {
      for ( let i = 0; i < phase.processVotes.length; i++) {
        if ( phase.processVotes[i].open && ! this.modulesWithVotes.includes(phase.processVotes[i].process2ModuleId)) {
          this.modulesWithVotes.push(phase.processVotes[i].process2ModuleId);
        }
      }
    },

    // votedIdea() {
    //   return (this.idea.valueVotes1 > 0 || this.idea.valueVotes4 > 0);
    // }
  },
  filters:{
    truncate(text, length, clamp){
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    }
  }
}
</script>

<style scoped>

</style>
