<template>
  <b-modal  id="modal-sms" ref="modalSms" size="lg" centered hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHMOBILEVERIFY.PARTECIPAPROCESSO")}}:</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h4 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
         <div class="h4 text-primary">{{$t("ADVAUTHMOBILEVERIFY.VERIFICA1")}}</div>
          <p class="font-weight-bolder">{{$t("ADVAUTHMOBILEVERIFY.VERIFICA2")}}</p>
        <form class="form mt-5 mb-5">

          <div class="form-group mb-5">
            <div class="input-group">
              <input
                  class="form-control w-100 h-auto form-control-solid py-4 px-8"
                  type="text"
                  v-bind:placeholder="$t('ADVAUTHMOBILEVERIFY.PH1')"
                  name="otpCode"
                  id="otpCode"
                  ref="otpCode"
                  v-model="$v.form.otpCode.$model"
                  :state="validateState('otpCode')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{
                      invalid: $v.form.otpCode.$dirty && $v.form.otpCode.$invalid
                    }"
              />
              <template
                  v-if="$v.form.otpCode.$dirty && $v.form.otpCode.$invalid"
              >
                  <span v-if="!$v.form.otpCode.required" class="error"
                  >{{$t("ADVAUTHMOBILEVERIFY.OTPDIMENTICATO")}}</span
                  >
                <span v-if="!$v.form.otpCode.maxLength" class="error"
                >{{$t("ADVAUTHMOBILEVERIFY.ERROROTPMAXCHAR")}}</span
                >
              </template>
            </div>
          </div>
<!--          <p v-html='$t("ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE" /* #key_loc */)'></p>-->
          <p>
            <span v-html='$t("ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE1")'></span>
            <a ref="otp_resend" @click="resendOtpCode" ><span class="text-primary font-weight-bolder" style="cursor: pointer;">{{$t("ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE2")}}</span></a>
            {{$t("ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE3")}}
            <a href="mailto:support@bipart.it" target="blank">support@bipart.it</a>
          </p>

          <div class="form-group d-flex flex-wrap">
            <b-button v-if="seeBack" class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-phone>{{$t("ADVAUTHMOBILEVERIFY.INDIETRO")}}</b-button>
            <b-button
                class="btn btn-lg btn-primary text-uppercase font-weight-bolder" :class="{'ml-2': seeBack}"
                @click.prevent="checkOtp"
                id="otp_submit"
                ref="otp_submit"
            >{{$t("ADVAUTHMOBILEVERIFY.PROCEDI")}}
            </b-button>

          </div>

        </form>
      </div>
    </div>
  </b-modal>

</template>

<script>
import {validationMixin} from "vuelidate";
import {mapGetters} from "vuex";
import {required, maxLength} from "vuelidate/lib/validators";
import {REFRESH_TOKEN, RESEND_OTP_CODE, SET_HEADER_TOKEN, VERIFY_MOBILE} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import {commonMixin} from "@/mixins/common";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "advMobileVerify",
  mixins: [validationMixin, commonMixin],
  props: {
    seeBack: { default: true, required: false }
  },
  data() {
    return {
      form: {
        otpCode: ""
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
  },
  mounted() {},
  validations: {
    form: {
      otpCode: {
        required,
        maxLength: maxLength(6)
      }
    },
  },
  methods: {
    close() {
      this.$refs["modalPhone"].hide();
    },
    checkOtp() {
      this.onSubmit();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resendOtpCode() {
      //console.log('Reinvio di un nuovo codice OTP');
      const otpResendButton = this.$refs["otp_resend"];
      otpResendButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store.dispatch(RESEND_OTP_CODE, this.currentUser.id);

      otpResendButton.classList.remove("spinner","spinner-light","spinner-right");

      Swal.fire(
          this.$t("ADVAUTHMOBILEVERIFY.ALERT1TITLE"),
          this.$t("ADVAUTHMOBILEVERIFY.ALERT1"),
        "info"
      );

    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["otp_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let verifyData = {
        id: this.currentUser.id,
        verify: {
          smsCode: this.$v.form.otpCode.$model,
          locale: i18nService.getActiveLanguage(),
          // fake: true
        }
      }
      // console.log("OPT code: ", verifyData);

      await this.$store
        .dispatch(VERIFY_MOBILE, verifyData)
        .then(response => {
          console.log('Risposta dalla promise register in then: ', response);
          if (response.status == 200) {
            this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
            this.$store.dispatch(SET_HEADER_TOKEN)
            this.$emit('switchToTaxcode');
            this.$refs['modalSms'].hide();
          } else if(response.status == 400) {
            this.manageAxiosErrors(response);
          }  else {
            Swal.fire(
              "Error",
              "Ops... something went wrong; Status code: " + response.status,
              "info"
            );
          }
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
        .catch(err => {
          this.manageAxiosErrors(err);
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    }
  }
}
</script>

<style scoped>
.error{
  color:#f00;
}
.invalid {
  border-color: #f00;
}
</style>
