<template>
  <div class="card card-custom card-stretch border-primary-hover shadow-sm gutter-b zoom ribbon ribbon-clip ribbon-right" style="width: 300px !important;">
    <!--Ribbon Assessment-->
    <router-link v-if="idIdeaRouter"
        :to="{
          name: 'processModuleIdeaDetail',
          query: {
            forced_evaluate: true
          },
          params: {
            id: idIdeaRouter,
            process_id: processId,
            phase_id: phaseId,
            module_id: moduleId,
            phase_status_id: phaseStatusId
          }
        }"
    >
      <div class="ribbon-target p-3" style="top: 145px;" v-if="publishAssessmentsMaster && ideaSimpleAssessmentStatusId > 1">
        <span class="ribbon-inner" :style="'color:#fff; background-color:' + ideaSimpleAssessmentStatusColor"></span>
        <span class=" text-uppercase font-weight-bolder">{{assessment}}</span>
      </div>
    </router-link>
    <!--end::Ribbon Assessment-->

    <!--Ribbon Versioning-->
    <div v-if="activeVersioning" class="ribbon-target p-3" style="top: 20px;">
      <span class="ribbon-inner bg-primary"></span>
      <span class="text-uppercase font-weight-bolder h3">{{versionNumber}}</span>
    </div>
    <!--end::Ribbon Versioning-->

    <!--Body-->
    <div class="card-body ribbon ribbon-top ribbon-ver pb-2">

      <!--Ribbon Voted-->
      <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;" v-if="type1VotedByAuthUser || type4VotedByAuthUser > 0">
        <i class="fa fa-star text-white mr-3"></i> <span class="font-bolder">{{$t("PARTIALSIDEE.VOTATA")}}</span>
      </div>

      <!--Logo-->
      <div class="thumbnail-idea-small">
        <img :src="logo" class="portait"/>
      </div>

      <!--Title & Description-->
      <h3 class="mt-5"> {{ title | truncate(50, '...') }}</h3>
      <p class="mt-5">{{ description | truncate(90, '...') }}</p>

      <!--Budget-->
      <span class="font-size-h5 font-weight-bold mr-6" v-if="budgetMin">{{$t("PARTIALSIDEE.BUDGET")}} {{budgetMin}}</span>
    </div>
    <!--end::Body-->

    <!--Footer-->
    <div class="card-footer d-flex justify-content-end border-0 p-0 pb-2 px-0">

      <!--Comments-->
      <div v-if="activeCommenting" class="d-flex flex-column">
        <span class="p-2 mr-1 mt-2"><i class="fas fa-comment text-primary"></i>&nbsp;{{countIdeaComments}}</span>
      </div>

      <!--Followers-->
      <div v-if="$canFollow" class="d-flex flex-column">
        <button v-if="followedByAuthUser" @click="delFollower()" class="btn btn-sm btn-default btn-text-primary mr-2 mt-2">
          <i class="fas fa-heart text-danger"></i>
          <span class="pl-3">{{countFollowers}}</span>
        </button>

        <button v-else @click="addFollower()" class="btn btn-sm btn-default btn-text-primary mr-2 mt-2">
          <i class="fas fa-heart text-primary"></i>
          <span class="pl-3">{{countFollowers}}</span>
        </button>
      </div>
      <span v-else-if="$activeFollowing" class="p-2 mr-1 mt-2"><i class="fas fa-heart text-danger"></i>&nbsp;{{countFollowers}}</span>

      <!--Button-->
      <div class="d-flex flex-column" v-if="buttonView">
        <router-link v-if="idIdeaRouter"
            :to="{
            name: 'processModuleIdeaDetail',
              params: {
                id: idIdeaRouter,
                process_id: processId,
                phase_id: phaseId,
                module_id: moduleId,
                phase_status_id: phaseStatusId
              }
            }"
            class="mr-2"
        >
          <span class="mt-1 btn btn-primary btn-block font-weight-bolder text-uppercase" >{{$t("PARTIALSPROCESSI.VOTA")}}</span>
        </router-link>
      </div>
      <div class="d-flex flex-column" v-else>
        <router-link v-if="idIdeaRouter"
            :to="{
            name: 'processModuleIdeaDetail',
              params: {
                id: idIdeaRouter,
                process_id: processId,
                phase_id: phaseId,
                module_id: moduleId,
                phase_status_id: phaseStatusId
              }
            }"
            class="mr-2"
        >
          <span class="mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase" >{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</span>
        </router-link>
      </div>
    </div>
    <!--end::Footer-->

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GmapInfoWindowCard",
  props: {
    idea: Object
  },

  data() {
    return {
      ideaData: null,
      id: null,
      idIdeaRouter: null,
      processId: null,
      phaseId: null,
      moduleId: null,
      module: null,
      phaseStatusId: null,
      modulesWithVotes: null,
      publishAssessmentsMaster: null,
      ideaSimpleAssessmentStatusId: null,
      ideaSimpleAssessmentStatusColor: null,
      assessment: null,
      versionNumber: null,
      activeVersioning: null,
      type1VotedByAuthUser: null,
      type4VotedByAuthUser: null,
      logo: null,
      title: null,
      description: null,
      budgetMin: null,
      countIdeaComments: null,
      activeCommenting: null,
      followedByAuthUser: null,
      countFollowers: null,
      buttonView: null
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
  },

  methods: {
    addFollower() {
      this.$emit("addFollower", this.id);
    },
    delFollower() {
      this.$emit("delFollower", this.id);
    }
  },

  watch :{
    idea: {
      async handler(newIdea) {

        this.ideaData = newIdea?.markerIdea;
        let params = newIdea?.params;

        this.processId = params?.process_id;
        this.phaseId = params?.phase_id;
        this.moduleId = params?.module_id;
        this.module = params?.module;
        this.phaseStatusId = params?.phase_status_id;
        this.modulesWithVotes = params?.modulesWithVotes;

        this.id = this.ideaData?.id ?? '';
        this.idIdeaRouter = this.ideaData?.idIdeaRouter ?? '';
        this.title = this.ideaData?.title ?? '';
        this.description = this.ideaData?.description ?? '';
        this.publishAssessmentsMaster = (params?.module?.publishAssessmentsMaster || (newIdea && this.ideaData?.assessor && this.ideaData?.assessor?.id && this.ideaData?.assessor?.id == this.currentUser.id));
        this.ideaSimpleAssessmentStatusId = this.ideaData?.ideaSimpleAssessmentStatus?.id ?? 1;
        this.ideaSimpleAssessmentStatusColor = this.ideaData?.ideaSimpleAssessmentStatus?.color ?? '';
        this.assessment = this.ideaData?.ideaSimpleAssessmentStatus?.localizations[0]?.name ?? '';
        this.versionNumber = this.ideaData?.versionNumber ?? '';
        this.activeVersioning = (this.ideaData?.versionNumber && this.ideaData?.versionNumber > 0) && params?.module && params?.module?.activeVersioning;
        this.type1VotedByAuthUser = this.ideaData?.type1VotedByAuthUser ?? '';
        this.type4VotedByAuthUser = this.ideaData?.type4VotedByAuthUser ?? '';
        this.logo = this.ideaData?.logo?.uri ?? '/assets/media/bipart/idea-default.svg';
        this.budgetMin = this.ideaData?.budgetMin ?? '';
        this.countIdeaComments = this.ideaData?.countIdeaComments ?? '';
        this.activeCommenting = params?.module && params?.module?.activeCommenting;
        this.followedByAuthUser = this.ideaData?.followedByAuthUser ?? '';
        this.countFollowers = this.ideaData?.countFollowers ?? '';
        this.buttonView = params?.phase?.processVotes?.length > 0 && this.modulesWithVotes.includes(this.moduleId);
      },
      deep: true
    }
  },

  filters:{
    truncate(text, length, clamp){
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    }
  }
}
</script>

<style scoped></style>