<template>
  <b-modal centered hide-backdrop hide-footer ok-only no-stacking
    id="modal-register-on-public"
    ref="modalAdvRegisterOnPublic"
    size="lg"
    content-class="shadow"
    @hidden="resetForm"
  >
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t('ADVREGISTERONPUBLIC.PARTECIPAPROCESSO')}}</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h2 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
        <div class="h2 text-primary">{{$t('ADVREGISTERONPUBLIC.PARTECIPA')}}</div>
        <h3>{{ this.process.title }}</h3>
        <form class="form mt-5" @submit.stop.prevent="onSubmit">
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="text"
              v-bind:placeholder="$t('ADVREGISTERONPUBLIC.PH1')"
              name="name"
              id="name"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{ invalid: $v.form.name.$dirty && $v.form.name.$invalid }"
            />
            <template v-if="$v.form.name.$dirty && $v.form.name.$invalid">
              <span v-if="!$v.form.name.required" class="error pl-5"
              >{{$t("ADVREGISTERONPUBLIC.NOMEDIMENTICATO")}}</span
              >
            </template>
          </div>
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="text"
              v-bind:placeholder="$t('ADVREGISTERONPUBLIC.PH2')"
              name="surname"
              id="surname"
              v-model="$v.form.surname.$model"
              :state="validateState('surname')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{
                invalid: $v.form.surname.$dirty && $v.form.surname.$invalid
              }"
            />
            <template v-if="$v.form.surname.$dirty && $v.form.surname.$invalid">
              <span v-if="!$v.form.surname.required" class="error pl-5"
              >{{$t("ADVREGISTERONPUBLIC.COGNOMEDIMENTICATO")}}</span
              >
            </template>
          </div>
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="text"
              v-bind:placeholder="$t('ADVREGISTERONPUBLIC.PH3')"
              name="username"
              id="username"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{
                invalid: ($v.form.username.$dirty && ($v.form.username.$invalid || checkForbiddenCharacters('@', $v.form.username.$model)))
              }"
            />
            <template
                v-if="$v.form.username.$dirty && ($v.form.username.$invalid || checkForbiddenCharacters('@', $v.form.username.$model))"
            >
              <div v-if="!$v.form.username.required" class="error pl-5"
              >{{$t("ADVREGISTERONPUBLIC.USERNAMEDIMENTICATO")}}</div
              >
              <div v-if="checkForbiddenCharacters('@', $v.form.username.$model)" class="error pl-5">{{ $t("REGISTER.USERNAMEFORBIDDENCHARACTERS") }}</div>
              <div v-if="$v.form.username.$model.length > 0 && $v.form.username.$model.length < $v.form.username.$params.minLength.min" class="error pl-5">{{ $t("REGISTER.USERNAMEMINLENGTH") }}</div>
            </template>
          </div>
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="email"
              v-bind:placeholder="$t('ADVREGISTERONPUBLIC.PH4')"
              name="email"
              id="email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{
                invalid: $v.form.email.$dirty && $v.form.email.$invalid
              }"
            />
            <template v-if="$v.form.email.$dirty && $v.form.email.$invalid">
              <span v-if="!$v.form.email.required" class="error pl-5"
              >{{$t("ADVREGISTERONPUBLIC.MAILDIMENTICATA")}}</span
              >
              <span v-if="!$v.form.email.email" class="error pl-5"
            >{{$t("ADVREGISTERONPUBLIC.MAILNONVALIDA")}}</span
              >
            </template>
          </div>
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="password"
              v-bind:placeholder="$t('ADVREGISTERONPUBLIC.PH5')"
              name="password"
              id="password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{
                invalid: $v.form.password.$dirty && $v.form.password.$invalid
              }"
            />
            <template
              v-if="$v.form.password.$dirty && $v.form.password.$invalid"
            >
              <span v-if="!$v.form.password.required" class="error pl-5"
              >{{$t("ADVREGISTERONPUBLIC.PASSWORDDIMENTICATA")}}</span
              >
            </template>
          </div>

          <div class="form-text text-muted text-small my-3">{{$t("ADVREGISTERONPUBLIC.CAMPIOBBLIGATORI")}}</div>

          <div class="form-group mb-5 text-left">
            <label class="checkbox m-0">
              <input
                type="checkbox"
                name="term_and_conditions"
                v-model="term_and_conditions"/>
                <span></span>
               <div v-html='$t("ADVREGISTERONPUBLIC.TERMINIECONDIZIONI")'></div>
            </label>
            <div class="form-text text-muted text-center"></div>
          </div>
          <div class="form-group mb-5 text-left">
            <label class="checkbox m-0">
              <input type="checkbox" name="privacy" v-model="privacy"/>
              <span></span>
              <div v-html='$t("ADVREGISTERONPUBLIC.PRIVACY")'></div>
            </label>
            <div class="form-text text-muted text-center"></div>
          </div>

<!--          <div v-if="this.process.guidelines"-->
<!--               class="form-group mb-5 text-left">-->
<!--            <label class="checkbox m-0">-->
<!--              <input type="checkbox" name="process_guidelines" v-model="process_guidelines" />-->
<!--              <span></span>-->
<!--              <div>-->
<!--                <span v-html='$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA1")'></span>-->
<!--                <a :href="'/process-guidelines/' + process.id" target="_blank">-->
<!--                  {{$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA2")}}</a>-->
<!--              </div>-->
<!--            </label>-->
<!--            <div class="form-text text-muted text-center"></div>-->
<!--          </div>-->


          <div class="form-group mb-5 text-left">
            <label class="checkbox m-0">
              <input
                type="checkbox"
                name="newsletter"
                v-model="newsletter"/>
                <span></span>
                <div v-html='$t("ADVREGISTERONPUBLIC.NEWSLETTER")'></div>
            </label>
          </div>

          <div class="form-group d-flex flex-wrap text-center">
            <vue-recaptcha sitekey="6Lc10EkbAAAAAAaNg5K4SeEriJSMIp5YHg9wbGSr" @verify="verifyReCaptcha" @expired="resetReCaptcha" :loadRecaptchaScript="false"></vue-recaptcha>
          </div>

          <div class="form-group d-flex flex-wrap">
            <b-button
              class="btn btn-lg btn-default text-uppercase font-weight-bolder"
              v-b-modal.modal-method
            >{{$t("ADVREGISTERONPUBLIC.INDIETRO")}}</b-button
            >
            <b-button
              :disabled="isDisabled"
              class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
              @click.prevent="registra"
              id="register_anonymous_submit"
              ref="register_anonymous_submit"
            >{{/*$t("ADVREGISTERONPUBLIC.PROCEDI")*/ /* #key_loc */ }}
              {{ $t("ADVREGISTERONPUBLIC.REGISTRA") }}
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { processMixin } from "@/mixins/process";
import {commonMixin} from "@/mixins/common";
import VueRecaptcha from 'vue-recaptcha';
import i18nService from "@/core/services/i18n.service.js";

import { email, minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {REFRESH_TOKEN, SET_HEADER_TOKEN, REGISTER} from "@/core/services/store/auth.module";

export default {
  name: "advRegisterOnPublic",
  props: {
    process: process
  },
  mixins: [validationMixin, processMixin, commonMixin],
  data() {
    return {
      recaptcha: false,
      term_and_conditions: false,
      privacy: false,
      newsletter: false,
      form: {
        name: "",
        surname: "",
        username: "",
        email: "",
        password: ""
      },
      // process_guidelines: false
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      surname: {
        required
      },
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  components: {
    VueRecaptcha
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "getOrganization"]),
    isDisabled: function() {
      return !this.privacy || !this.term_and_conditions || !this.recaptcha;
      // if( this.process.guidelines && this.process.guidelines != "") {
      //   return !this.privacy || !this.term_and_conditions || !this.process_guidelines || !this.recaptcha;
      // } else {
      //   return !this.privacy || !this.term_and_conditions || !this.recaptcha;
      // }
    }
  },
  mounted() {
    this.loadRecaptcha();
  },
  methods: {
    checkForbiddenCharacters(char, target) {
      let regex = new RegExp(char)
      // console.debug("regex: ", regex)
      console.debug("forbiddenCharacters: ", regex.test(target))
      return regex.test(target);
    },
    loadRecaptcha () {
      const url = `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=` + i18nService.getActiveLanguage();
      const $el = document.createElement('script');
      $el.src = url;
      $el.async = true;
      document.head.appendChild($el);
    },
    close() {
      this.$refs["modalAdvRegisterOnPublic"].hide();
    },
    registra() {
      this.onSubmit();
    },
    switchToLogin() {
      this.$refs["modalAdvRegisterOnPublic"].hide();
      this.$emit('switchToLogin');
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? (!$error && !this.checkForbiddenCharacters('@', this.$v.form.username.$model)) : null;
    },

    resetForm() {

      this.recaptcha = false;
      this.term_and_conditions = false;
      this.privacy = false;
      this.newsletter = false;

      this.form = {
        name: null,
        surname: null,
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    verifyReCaptcha(response) {
      if(response != null && response != '') {
        this.recaptcha = true;
      }
    },
    resetReCaptcha() {
      this.recaptcha = false;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if(!this.recaptcha) {
        return;
      }

      if (!this.term_and_conditions || !this.privacy) {
        return;
      }

      if (this.checkForbiddenCharacters('@', this.$v.form.username.$model)) {
        return
      }

      // set spinner to submit button
      const submitButton = this.$refs["register_anonymous_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let registrationData = {
        username: this.$v.form.username.$model,
        password: this.$v.form.password.$model,
        email: this.$v.form.email.$model,
        name: this.$v.form.name.$model,
        surname: this.$v.form.surname.$model,
        termsConditionsAccepted: this.term_and_conditions,
        privacyPolicyAccepted: this.privacy,
        subscribedToNewsletter: this.newsletter,
        organizationDomain: this.$currentDomain,
        callbackPath: "email-confirm",
        processId: this.process.id,
        groupId: null
      };

      console.log("registrationData: ", registrationData);

      await this.$store
        .dispatch(REGISTER, registrationData)
        .then(response => {
          // console.log('Risposta dalla promise register in then: ', response);
          if (response.status == 200 || response.status == 201) {
            console.log('Aggiorno il token');
            this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
            this.$store.dispatch(SET_HEADER_TOKEN);
            this.$emit("userRegistered");
            this.$refs["modalAdvRegisterOnPublic"].hide();
          } else {
            Swal.fire(
                this.$t("ADVREGISTERONPUBLIC.ALERT1TITLE"),
                this.$t("ADVREGISTERONPUBLIC.ALERT1"), + response.status,
              "info"
            );
          }
          // submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
        .catch(err => {
          this.manageAxiosErrors(err);
          // submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        })
        .finally(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    }
  }
};
</script>

<style scoped>
.error{
  color: #ff0000;
}
.invalid{
  border-color: #ff0000;
}

</style>
