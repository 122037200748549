<template>
  <b-modal centered hide-backdrop hide-footer ok-only no-stacking
    id="modal-authRole"
    ref="modalRole"
    size="lg"  content-class="shadow">
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHROLE.PARTECIPAPROCESSO")}}</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
         <div class="h4 text-primary">{{$t("ADVAUTHROLE.VERIFICA1")}}</div>
        <p class="font-weight-bolder">{{$t("ADVAUTHROLE.VERIFICA2")}}</p>
        <form class="form mt-5 mb-5">
          <div class="form-group mb-5">
            <b-form-select
              v-model="selected"
              :options="options"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              class="bg-light-primary"
            ></b-form-select>
          </div>
          <!-- NICE TO HAVE
          quando selezione opzione da tendina mi mostra la scritta con gli attributi da validare a seguire: -->
          <b-card class="mb-5">
            <p class="mb-0" v-html='$t("ADVAUTHROLE.INFOREGISTER")'></p>
          </b-card>
          <div class="form-group d-flex flex-wrap">
            <b-button v-if="is_anonymous" class="btn btn-lg btn-default text-uppercase font-weight-bolder" v-b-modal.modal-method>{{$t("ADVAUTHROLE.INDIETRO")}}</b-button>
            <b-button v-if="!is_anonymous" class="btn btn-lg btn-default text-uppercase font-weight-bolder" @click="close">{{$t("ADVAUTHROLE.INDIETRO")}}</b-button>
            <b-button
              class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
              @click.prevent="setRole"
              id="role_submit"
              ref="role_submit"
            >{{$t("ADVAUTHROLE.PROCEDI")}}
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "advRoleAuth",
  data() {
    return {
      selected: 'cittadino',
      options:[
        { item: 'cittadino', name: this.$t("ADVAUTHROLE.CITTADINO") },
        // { item: 'codice-fiscale', name: 'Codice fiscale' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
    is_anonymous(){
      return (this.currentUser.authorities && this.currentUser.authorities.includes('SCOUT'));
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$refs["modalRole"].hide();
    },
    setRole() {
      console.log('Ruolo scelto:', this.selected);
      this.$emit('switchToMobile', this.selected);
    },

  }
}
</script>

<style scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
</style>
