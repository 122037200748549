<template>
  <b-modal centered hide-header hide-backdrop hide-footer ok-only no-stacking
    id="modal-not-valid"
    ref="modalTaxcodeNotValid"
    content-class="shadow" >
    <div class="text-center mt-10">
<!--      <img-->
<!--          :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"-->
<!--          class="h-60px"-->
<!--          alt="Logo"-->
<!--      />-->
<!--      <p class="my-4 font-weight-bolder h4">{{ getOrganization.data.name }}</p>-->
      <h2 class="mt-5">{{$t("ADVAUTHTAXCODENOTVALID.UTENTENONAUTORIZZATO")}}</h2>
      <p class="lead mb-5">{{$t("ADVAUTHTAXCODENOTVALID.CFNONABILIATO")}}</p>
      <p class="text-dark">{{$t("ADVAUTHTAXCODENOTVALID.CFNONABILIATO1")}} </p>
      <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder" @click="close">{{$t("ADVAUTHTAXCODENOTVALID.CHIUDI")}}</b-button>
    </div>
  </b-modal>
</template>

<script>
// import {mapGetters} from "vuex";

export default {
  name: "advAuthTaxcodeNotValid",
  props: ['process'],
  // computed: {
  //   ...mapGetters([
  //     "getOrganization",
  //     "currentUser"
  //   ]),
  // },
  methods: {
    close() {
      this.$refs["modalTaxcodeNotValid"].hide();
    },
  }

}
</script>

<style scoped>

</style>
