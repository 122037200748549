<template>
  <div class="row h-100 justify-content-between">

    <div class="d-none d-lg-block col-lg-2 col-xl-2 text-white align-items-stretch bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
          >
        </Phaseline>
      </div>
    </div>

    <!-- Colonna 2  -->
    <div class="col-lg-9 col-xl-9">

      <div class="row">
        <div class="col-lg-12">

          <div v-if="canVersioning()" class="alert alert-custom alert-2x bg-primary fade show my-5 py-2 mx-6" role="alert">
            <div class="row align-items-center w-100">

              <div class="col-1 p-0 m-0 align-items-center text-center">
                <i class="fas fa-layer-group text-white" style="font-size: 3em;"></i>
              </div>

              <div class="col-12 col-md-11 p-0 m-0 mb-3 mb-md-0 align-items-center">
                <div class="alert-text text-uppercase font-weight-bolder ">
                  <h6 class="aling-items-center mt-2 text-white">
                    {{$t("IDEALIST.ALERTVERSIONING1")}}
                  </h6>
                  <span class="aling-items-center mt-2 text-white">
                      {{ $t(($handleContentTypeLabel(moduleContentType) + "LIST.ALERTVERSIONING2")) + ((this.maxVersionsPerContent>0) ? (". " + $t("IDEALIST.ALERTVERSIONING5", { n: this.maxVersionsPerContent})) : '') }}
                  </span>
                </div>
              </div>

              <div v-if="this.maxVersionsPerContent>0 && true==false" class="col-6 col-md-4 m-0 text-right">
                <h3 class="text-white">{{this.maxVersionsPerContent}}</h3>
                <span class="text-uppercase text-white">{{$t("IDEALIST.ALERTVERSIONING3")}}</span>
              </div>

              <div v-if="true==false" class="col-6 col-md-2 m-0 text-right">
                <h3 class="text-white">{{this.maxCompletionDaysPerVersionDate}}</h3>
                <span class="ml-2 text-uppercase text-white">{{$t("IDEALIST.ALERTVERSIONING4")}}</span>
              </div>

            </div>
          </div>

          <!--::Idea Alert Vote -->
          <div v-if="showAlertVote" class=" my-5 mx-6">
            <!--
            <div
                class="alert alert-custom alert-outline-2x alert-danger fade show mb-2 py-2 px-2"
                role="alert"
                v-if="phase.phaseStatus && phase.phaseStatus.id == 2 && phase.processVotes.length > 0 && modulesWithVotes.includes(module.id)"
            >
              <span class="alert-icon"><i class="fa fa-heart icon-md"></i></span>
              <div class="d-flex align-items-end">
                <span class="alert-text font-weight-bolder mr-4"> {{$t("IDEALIST.VOTAZIONEATTIVA")}}!</span>
                <div class="alert-text mr-4">
                  {{$t("IDEALIST.VOTAZIONEATTIVA2")}}
                  <span v-if="this.voteParams.types.includes(1)">
                    {{$t("IDEALIST.VOTAZIONEATTIVAPREF")}}
                  </span>
                  <span v-if="this.voteParams.types.includes(4)">
                    e {{$t("IDEALIST.VOTAZIONEATTIVABUDG")}}.
                  </span>
                  <span v-if="(this.voteParams.types.includes(1) || this.voteParams.types.includes(4)) && this.processIdeas.length > 0 && this.currentUser.id !== 5">
                      Hai ancora a disposizione
                    <span v-if="this.voteParams.types.includes(1)"> {{ this.processIdeas[0].leftValueVotes1ForP2MIdeasByAuthUser}} preferenze</span>
                    <span v-if="this.voteParams.types.includes(4)"> un budget di {{ this.processIdeas[0].leftValueVotes4ForP2MIdeasByAuthUser}}€</span>
                  </span>
                </div>
                <div v-if="this.voteParams.types.includes(4)">
                  <div class="alert-text mr-2"> {{$t("IDEALIST.VOTAZIONEATTIVA3")}}</div>
                  <div v-if="this.voteParams.borders.min" class="alert-text mr-2 ">{{$t("IDEALIST.VOTAZIONEATTIVAMIN")}} <span class="font-weight-bolder h5">{{ this.voteParams.borders.min }}€</span></div>
                  <div v-if="this.voteParams.borders.max" class="alert-text mr-2">{{$t("IDEALIST.VOTAZIONEATTIVAMAX")}} <span class="font-weight-bolder h5">{{ this.voteParams.borders.max }}€</span></div>
                </div>
              </div>
            </div>
            <div
                class="alert alert-custom alert-outline-2x alert-outline-danger fade show mb-2 py-2"
                role="alert"
                v-else-if="phase.phaseStatus && phase.phaseStatus.id == 2 && phase.processVotes.length > 0 && !modulesWithVotes.includes(module.id)"
            >
              <div class="alert-icon"><i class="fa fa-heart icon-md"></i></div>
              <div class="alert-text text-left"> {{$t("IDEALIST.VOTAZIONECHIUSA")}}</div>
              <div class="alert-close">
              </div>
            </div>
            -->

            <!--
            phase.phaseStatus &&
            phase.phaseStatus.id == 2 &&
            phase.processVotes.length > 0 &&
            -->
            <div
                class="alert alert-custom alert-outline-2x alert-danger fade show mb-2 py-2 px-2"
                role="alert"
                v-if="(moduleVote1IsOpen || moduleVote4IsOpen)"
            >
              <span class="alert-icon"><i class="fa fa-heart icon-md"></i></span>
              <div class="d-flex align-items-end">
                <span class="alert-text font-weight-bolder mr-4"> {{$t("IDEALIST.VOTAZIONEATTIVA")}}!</span>
                <div v-if="is_anonymous_on_public" class="alert-text mr-4">
                  {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.VOTAZIONEATTIVAUTENTEANONIMO"))}}
                </div>
                <div v-else class="alert-text mr-4">
                  {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.VOTAZIONEATTIVA2"))}}<br />
                  <!-- Messaggio per votazione con sole preferenze -->
                  <span v-if="moduleVote1IsOpen">
                    {{$t("IDEALIST.VOTAZIONEATTIVAPREF")}} {{ this.voteParams.maxVote1}}
                    {{$t("IDEALIST.VOTAZIONEATTIVAPREF2")}}
                    <span v-if="this.processIdeas.length > 0">
                     {{this.processIdeas[0].leftValueVotes1ForP2MIdeasByAuthUser}}
                      <span v-if="this.processIdeas[0].leftValueVotes1ForP2MIdeasByAuthUser == 1">
                        {{$t("IDEALIST.VOTAZIONEATTIVAPREF4")}}
                      </span>
                      <span v-else>
                        {{$t("IDEALIST.VOTAZIONEATTIVAPREF3")}}
                      </span>
                    </span>
                  </span>

                  <!-- Messaggio per votazione con preferenze e budget -->
                  <span v-if="moduleVote1IsOpen && moduleVote4IsOpen && this.processIdeas.length > 0">
                    {{$t("IDEALIST.VOTAZIONEATTIVABUDG")}} {{ this.processIdeas[0].leftValueVotes4ForP2MIdeasByAuthUser}}€
                  </span>
<!--                  <span v-if="moduleVote4IsOpen">-->
<!--                    e {{$t("IDEALIST.VOTAZIONEATTIVABUDG")}}.-->
<!--                  </span>-->
<!--                  <span v-if="(moduleVote1IsOpen || moduleVote4IsOpen) && this.processIdeas.length > 0 && this.currentUser.id !== 5">-->
<!--                      Hai ancora a disposizione-->
<!--                    <span v-if="moduleVote1IsOpen"> {{ this.processIdeas[0].leftValueVotes1ForP2MIdeasByAuthUser}} preferenze</span>-->
<!--                    <span v-if="moduleVote4IsOpen"> un budget di {{ this.processIdeas[0].leftValueVotes4ForP2MIdeasByAuthUser}}€</span>-->
<!--                  </span>-->
                </div>
                <!-- Altro messaggio per votazione con preferenze e budget -->
                <div v-if="moduleVote4IsOpen">
                  <div class="alert-text mr-2"> {{$t("IDEALIST.VOTAZIONEATTIVA3")}}</div>
                  <div v-if="this.voteParams.borders.min" class="alert-text mr-2 ">{{$t("IDEALIST.VOTAZIONEATTIVAMIN")}} <span class="font-weight-bolder h5">{{ this.voteParams.borders.min }}€</span></div>
                  <div v-if="this.voteParams.borders.max" class="alert-text mr-2">{{$t("IDEALIST.VOTAZIONEATTIVAMAX")}} <span class="font-weight-bolder h5">{{ this.voteParams.borders.max }}€</span></div>
                </div>

              </div>
            </div>

            <!--
            phase.phaseStatus &&
            phase.phaseStatus.id == 2 &&
            phase.processVotes.length > 0 &&
            -->
            <div
                class="alert alert-custom alert-outline-2x alert-outline-danger fade show mb-2 py-2"
                role="alert"
                v-else-if="(!moduleVote1IsOpen && !moduleVote4IsOpen && !publishVotes)"
            >
              <div class="alert-icon"><i class="fa fa-heart icon-md"></i></div>
              <div class="alert-text text-left"> {{$t("IDEALIST.VOTAZIONECHIUSA")}}</div>
              <div class="alert-close">
              </div>
            </div>

          </div>

          <div class="d-flex flex-column mx-6" :class="{
            'mt-5': !canVersioning() && !showAlertVote
          }">
            <!--::Process Info -->
            <div
                class="card mb-3 card-stretch shadow-sm ribbon ribbon-clip ribbon-right rounded card shadow-sm border-0 justify-content-center bgi-size-cover bgi-no-repeat"
                :style="{'background-image': 'url(' + header_background + ')'}"
            >
              <div class="ribbon-target p-3" style="bottom: 20px;">
                <span
                    class="ribbon-inner"
                    v-bind:class="{
                    'bg-primary': phase.phaseStatus && phase.phaseStatus.id == 2,
                    'bg-warning': phase.phaseStatus && phase.phaseStatus.id == 1,
                    'bg-danger': phase.phaseStatus && phase.phaseStatus.id == 3
                  }"
                ></span
                ><span class=" text-uppercase font-weight-bolder" v-if="phase.phaseStatus">{{
                  phase.phaseStatus.name
                }}</span>
              </div>
              <div class="card-header bg-transparent border-0 d-flex justify-content-between pt-5 pb-2">

                <div class="d-flex">

                  <div class="d-flex align-items-center">
                    <span
                        v-if="!process.publiclyVisible"
                        class="label label-inline label-xl mr-2"
                        v-bind:class="{
                        'bg-primary': process.publiclyVisible,
                        'bg-danger': !process.publiclyVisible
                      }"
                    >
                      <i
                          class="fas fa-lock text-white"

                      ></i>
                    </span>
<!--                    <span v-else-->
<!--                        class="label label-inline label-xl mr-2"-->
<!--                        v-bind:class="{-->
<!--                        'bg-primary': process.publiclyVisible,-->
<!--                        'bg-danger': !process.publiclyVisible-->
<!--                      }"-->
<!--                    >-->
<!--                      <i class="fas fa-lock-open text-white" ></i>-->
<!--                    </span>-->

                    <span v-if="phase.openDate"> {{$t('GENERALLABEL.DAL')  | capitalize}} <b class="font-weight-bolder h5">{{ phase.openDate | moment("DD/MM/Y")}}</b> </span>
                    <span class="ml-4" v-if="phase.closeDate"> {{$t('GENERALLABEL.AL')}} <b  class="font-weight-bolder h5">{{ phase.closeDate | moment("DD/MM/Y")}}</b></span>

                  </div>
                </div>

              </div>
              <div class="card-body pt-3">
                <h1
                    class="text-primary font-weight-bolder mb-3"
                >
                  <router-link
                      :to="{
                      name: 'processDetail',
                      params: { id: process.id }
                    }"
                      class="text-primary"

                    v-if="hasProcessManyPhases"
                  >
                    {{ (process.localizations) ? process.localizations[0].title : '---' }}
                  </router-link>
<!--                  <span v-else-if="process.localizations">{{ process.localizations[0].title }}</span>-->
                  <span v-else>
                    <router-link
                        :to="{
                          name: 'processPhase',
                          params: {
                            process_id: process.id,
                            phase_id: phase.id
                          }
                        }"
                        class="text-primary"
                        v-if="hasPhaseManyModules"
                    >
                      {{ (process.localizations) ? process.localizations[0].title : '---' }}
                    </router-link>

                    <router-link
                        :to="{
                          name: 'processModuleIdeaList',
                          params: {
                            id: module.id,
                            process_id: process.id,
                            phase_id: phase.id
                          }
                        }"
                        class="text-primary"
                        v-else
                    >
                      {{ (process.localizations) ? process.localizations[0].title : '---' }}
                    </router-link>
                  </span>

                </h1>
                <router-link
                    v-if="module.process"
                    :to="{
                    name: 'processModuleIdeaList',
                    params: { id: module.id, process_id: module.process.id, phase_id: phase.id}
                  }"
                    v-bind:id="module.title"
                >
                  <div class="text-dark-75 h2 font-weight-bolder mb-3">
                     <span class="label label-circle bg-primary text-white p-6 h3 font-weight-bolder mr-5" v-if="process.countProcessPhases > 1">
                      {{ phase.seqStrict}}
                    </span>{{ phase.title }}
                  </div>
                </router-link>

                <div class="d-flex flex-row">
                  <a
                      v-if="this.phase.description /* campo backend: descrizione della fase */"
                      class="btn btn-default text-dark-75 font-weight-bolder rounded-0 mr-2 px-3 mr-2"
                      @click.prevent="alertInfoProcess"
                  >
                    <i class="fas fa-info-circle text-dark-75 "></i> {{$t("IDEALIST.INFORMAZIONI")}}
                  </a>

                  <div class="" v-if="module.canCreate">

                    <div v-if="is_anonymous_on_public && !canAccessModuleAnonymous">
                      <a @click.prevent="alertUserModuleAccessBitmaskCreate(moduleContentType)">
                        <div
                            class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2"
                        ><i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}</div>
                      </a>
                    </div>

                    <div v-else-if="!is_anonymous_on_public && !can_access_module('create')">
                      <a @click.prevent="alertUserModuleAccessBitmaskCreate(moduleContentType)">
                        <div
                            class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2"
                        ><i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}</div>
                      </a>
                    </div>

                    <div v-else-if="is_anonymous_on_public">
                      <a v-b-modal.modalLoginRegisterProcessOnPublic id="pop-plus">
<!--                      <a v-b-modal.modalRegisterOnPublic id="pop-plus">-->
                        <div class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2">
                          <i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}
                        </div>
                      </a>
                    </div>

                    <!--Mobile-Email Pending-->
                    <div v-else-if="!is_anonymous_on_public && is_mobile_pending && is_email_pending">
                      <a @click.prevent="alertEmailMobileConfirm" id="pop-plus">
                        <div class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2">
                          <i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}
                        </div>
                      </a>
                    </div>
                    <!--end::Mobile-Email Pending-->

                    <!--Mobile Pending-->
                    <div v-else-if="!is_anonymous_on_public && is_mobile_pending && !is_email_confirmed">
                      <a @click.prevent="alertMobileConfirm" id="pop-plus">
                        <div class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2">
                          <i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}
                        </div>
                      </a>
                    </div>
                    <!--end::Mobile Pending-->

                    <div v-else-if="!is_anonymous_on_public && is_email_pending && !is_mobile_confirmed">
                      <a @click.prevent="alertEmailConfirm" id="pop-plus">
                        <div class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2">
                          <i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}
                        </div>
                      </a>
                    </div>

                    <div v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)">
                      <a v-b-modal.modalLoggedRegisterProcessOnPublic
                         class="bg-transparent"
                         id="pop-plus">
                        <div class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2">
                          <i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}
                        </div>
                      </a>
                    </div>
                   
                    <div v-else>
                      <router-link
                          :to="{
                          name: 'processModuleIdeaCreate',
                          params: {
                          module_id: id,
                          process_id: process_id,
                          phase_id: phase_id
                          }
                          }"
                      >
                        <div
                            class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2"
                        ><i class="fas fa-plus pr-2"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.CREAIDEA"))}}</div>
                      </router-link>
                    </div>
                  </div>

                  <a v-if="processVoteIdForVoteType1" class="btn btn-light-primary font-weight-bolder rounded-0 mr-2 px-3 mr-2">
                    {{valueVotes1ForIdeas}} {{$t("IDEALIST.PREFERENZE")}}
                  </a>

                  <a v-if="processVoteIdForVoteType1" class="btn btn-light-primary font-weight-bolder rounded-0 mr-2 px-3 mr-2">
                    {{countVoters1ForIdeas}} {{$t("IDEALIST.VOTANTI")}}
                  </a>

                  <div v-if="publishVotes">
                    <router-link
                        :to="{
                        name: 'processModuleIdeaResult',
                        params: {
                          id: module.id,
                          process_id: module.process.id,
                          phase_id: phase.id
                        }
                      }"
                    >
                      <b-button class="btn btn-primary text-uppercase font-weight-bolder rounded-0 px-3 mr-2">
                        <i class="icon-md fas fa-award text-white"></i> <span>{{$t("IDEALIST.CLASSIFICA")}}</span>
                      </b-button>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>

            <!--Module alert-->
            <div class="alert alert-custom alert-2x bg-primary fade show my-2 py-2" role="alert">
              <div class="row alert-text text-uppercase font-weight-bolder align-items-center">

                <div class="col-12 col-md-9 align-items-center">
                  <router-link
                      v-if="module.process"
                      :to="{
                        name: 'processModuleIdeaList',
                        params: { id: module.id, process_id: module.process.id, phase_id: phase.id}
                      }"
                      v-bind:id="module.title"
                      v-slot="{ href, navigate }"
                  >
                    <a :href="href" @click="navigate">
                      <h5 class="aling-items-center mt-2 text-white"> {{ module.title }}</h5>
                    </a>
                  </router-link>
                </div>

                <div class="col-12 col-md-3 align-items-center text-right">
                  <h6 class="aling-items-center mt-2 text-white">{{moduleDateStr}}</h6>
                </div>
              </div>
            </div>
            <!--end::Module alert-->

            <!--Versioning alert 1-->
            <div class="alert alert-custom alert-2x bg-secondary fade show my-2 py-2" role="alert" v-if="this.ideaInPs && canVersioning()">
              <div class="alert-text text-uppercase font-weight-bolder ">
                <h5 class="aling-items-center mt-2 text-white"><i class="fas fa-layer-group text-white"></i> {{$t(($handleContentTypeLabel(moduleContentType) + "LIST.ALERTBOZZA"))}}
                  <router-link class="text-white" :to="{
                      name: 'personalSpaceActivities',
                    }">
                    <u>{{$t("IDEALIST.PS")}}</u>
                  </router-link>
                </h5>
              </div>
            </div>
            <!--end::Versioning alert-->
          </div>
        </div>
      </div>
      <!--:: TOOLS MOBILE-->
      <div class="row d-block d-lg-none mx-6 my-3">
        <div class="col-lg-12">
          <!-- Vote recap  -->
          <!-- <div class="btn-group mr-2" role="group" id="kt_quick_panel_toggle" >
            <router-link
                v-slot="{ href, navigate }"
                :to="{

              }"
            >
              <button type="button" class="btn btn-primary"><i class="fas fa-star text-white pr-0 pb-1"></i></button>
            </router-link>
          </div> -->
          <!-- how it works  -->
          <div class="btn-group mr-2" role="group">
<!--            <router-link-->
<!--                to="/how-it-works"-->
<!--            >-->
<!--              <button type="button" class="btn btn-primary"><i class="fas fa-book-open text-white pr-0 pb-1"></i></button>-->
<!--            </router-link>-->
          <ProcessMenuMobile :process_id="process_id" :is_process_faq="isFaq" :is_process_guidelines="isGuidelines"></ProcessMenuMobile>
          </div>
        </div>
      </div>
      <!--:: LIST IDEA-->
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-column mx-6">

            <!--::Search-->
            <div class="row w-100">
              <!-- Blocco search -->
              <div class="col-12 col-xl-12">
                <div class="row mx-6 w-100">
                  <form method="get" class="quick-search-form w-100">
                    <div class="input-group align-items-center">
                      <div class="input-group-append d-none d-sm-block">
                        <span
                            class="input-group-text h3 text-primary font-weight-bold bg-transparent border-0"
                        >
<!--                          {{ processIdeasCount }} {{$t("IDEALIST.IDEE")}}-->
                          {{ processIdeasCount }} {{$t($handleContentTypeLabel(moduleContentType) + "LIST.IDEE")}}
                        </span>
                      </div>
                      <div class="input-group-prepend bg-transparent  d-none d-sm-block">
                        <button
                            class="input-group-text line-height-0 py-0 bg-transparent border-0"
                            v-on:click="getSearchData($event)"
                        >
                          <img
                              src="/assets/media/bipart/ico-search.png"
                              alt=""
                              class="w-100"
                          />
                        </button>
                      </div>

                      <input
                          type="text"
                          class="form-control bg-transparent py-10 h4 border-0"
                          placeholder="Search..."
                          v-model="keywords"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <a
                              href="#"
                              @click="setView('list', $event)"
                              id="pop-list"
                          >
                          <i class="fas fa-list text-primary"></i>
                          </a>
                        </span>
                        <span class="input-group-text">
                          <a
                              href="#"
                              @click="setView('grid', $event)"
                              id="pop-grid"
                          >
                            <i class="fas fa-th text-primary"></i>
                          </a>
                        </span>
                        <span class="input-group-text" v-if="displayViewMap">
                          <a
                              href="#"
                              @click="setView('map', $event)"
                              id="pop-map"
                          >
                            <i class="fas fa-map text-primary"></i>
                          </a>
                        </span>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!--::Desktop filters-->
            <div class="card card-custom my-3 d-none d-md-block">
              <b-nav>
                <b-nav-item :active="selectedOrder != null" :disabled="view=='map' || processIdeas.length == 0">
                  <b-dropdown id="dropdown-order" :text='$t("IDEALIST.ORDINA")' variant="transparent">
                    <b-dropdown-item v-if="$doNOTShowIfTactic(moduleContentType)" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 1 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'desc'); handleSelectedOrder(1)">
                      <a href="#"
                      >{{$t("IDEALIST.ULTIMEIDEE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$doNOTShowIfTactic(moduleContentType)" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 2 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'asc'); handleSelectedOrder(2)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEMENORECENTI")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-divider v-if="$activeFollowing && $doNOTShowIfTactic(moduleContentType)"></b-dropdown-divider>
                    <b-dropdown-item style="white-space: normal !important;" v-if="$activeFollowing" :class="['min-w-200px', selectedOrder == 3 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'countFollowers', 'desc'); handleSelectedOrder(3)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEPIUSEGUITE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-item style="white-space: normal !important;" v-if="$activeFollowing" :class="['min-w-200px', selectedOrder == 4 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'countFollowers', 'asc'); handleSelectedOrder(4)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEMENOSEGUITE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-divider v-if="hasBudget"></b-dropdown-divider>
<!--                    <b-dropdown-item v-if="hasBudget" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 6 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'budgetMin', 'asc'); handleSelectedOrder(6)">-->
<!--                      <a href="#"-->
<!--                      >{{$t("IDEALIST.IDEEMENOCOSTOSE")}}</a-->
<!--                      >-->
<!--                    </b-dropdown-item>-->
                    <b-dropdown-item v-if="hasBudget" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 5 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'budgetMin', 'desc'); handleSelectedOrder(5)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEECOSTOSE")}}</a
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item>

                <b-nav-item v-if="module.countChallenges && module.countChallenges > 0" :disabled="challenges.length == 0" :active="selectedChallengeFilter !== 0 ? true : false">
                  <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERCHALLENGE")' variant="transparent" >
                    <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByChallenge($event, 0)">
                      <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-for="item in this.challenges"
                        :key="item.id"
                        style="white-space: normal !important;"
                        :class="['min-w-200px', item.id == selectedChallengeFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByChallenge($event, item.id)">
                      <a href="#">{{item.name}}</a>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item>

<!--                nuovo-->
                <b-nav-item v-if="process.countScenarios && process.countScenarios > 0" :disabled="processScenarios.length == 0" :active="selectedScenarioFilter !== 0 ? true : false">
                  <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERSCENARIO")' variant="transparent" >
                    <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByScenario($event, 0)">
                      <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-show="processScenarios.length > 0"
                        v-for="item in this.processScenarios"
                        :key="item.id"
                        style="white-space: normal !important;"
                        :class="['min-w-200px', item.id === selectedScenarioFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByScenario($event, item.id)">
                      <a href="#">{{item.title}}</a>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item>
<!-- /nuov -->


                <!-- <b-nav-item>
                  <b-dropdown id="dropdown-order" text="filtra per mappa" variant="transparent">
                    <b-dropdown-item class="min-w-200px">
                      <a href="#" v-on:click="filterByScenario($event, 0)">Nessuno</a>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="item in this.scenarios"
                      :key="item.id"
                      class="min-w-200px">
                    >
                      <a href="#" v-on:click="filterByScenario($event, item.id)">
                        {{item.name}}
                        </a>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item> -->
              </b-nav>
            </div>

            <!--::Mobile filters-->
            <div class="card card-custom my-3 d-block d-md-none">
              <b-nav>
                <b-nav-item :active="selectedOrder != null" :disabled="view=='map' || processIdeas.length == 0" class="w-100">
                  <b-dropdown id="dropdown-order" :text='$t("IDEALIST.ORDINA")' class="w-100" menu-class="w-100" variant="transparent">
                    <b-dropdown-item v-if="$doNOTShowIfTactic(moduleContentType)" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 1 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'desc'); handleSelectedOrder(1)">
                      <a href="#"
                      >{{$t("IDEALIST.ULTIMEIDEE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$doNOTShowIfTactic(moduleContentType)" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 2 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'asc'); handleSelectedOrder(2)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEMENORECENTI")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-divider v-if="$doNOTShowIfTactic(moduleContentType)"></b-dropdown-divider>
                    <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 3 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'countFollowers', 'desc'); handleSelectedOrder(3)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEPIUSEGUITE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 4 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'countFollowers', 'asc'); handleSelectedOrder(4)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEMENOSEGUITE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>

                    <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 5 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'budgetMin', 'desc'); handleSelectedOrder(5)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEECOSTOSE")}}</a
                      >
                    </b-dropdown-item>
                    <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 6 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'budgetMin', 'asc'); handleSelectedOrder(6)">
                      <a href="#"
                      >{{$t("IDEALIST.IDEEMENOCOSTOSE")}}</a
                      >
                    </b-dropdown-item>

                  </b-dropdown>
                </b-nav-item>

                <b-nav-item v-if="module.countChallenges && module.countChallenges > 0" :disabled="challenges.length == 0" :active="selectedChallengeFilter !== 0 ? true : false" class="w-100">
                  <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERCHALLENGE")' class="w-100" menu-class="w-100" variant="transparent" >
                    <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByChallenge($event, 0)">
                      <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-for="item in this.challenges"
                        :key="item.id"
                        style="white-space: normal !important;"
                        :class="['min-w-200px', item.id == selectedChallengeFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByChallenge($event, item.id)">
                      <a href="#">{{item.name}}</a>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item>

                <!--                nuovo-->
                <b-nav-item v-if="process.countScenarios && process.countScenarios > 0" :disabled="processScenarios.length == 0" :active="selectedScenarioFilter !== 0 ? true : false" class="w-100">
                  <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERSCENARIO")' class="w-100" menu-class="w-100" variant="transparent" >
                    <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByScenario($event, 0)">
                      <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-show="processScenarios.length > 0"
                        v-for="item in this.processScenarios"
                        :key="item.id"
                        style="white-space: normal !important;"
                        :class="['min-w-200px', item.id === selectedScenarioFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByScenario($event, item.id)">
                      <a href="#">{{item.title}}</a>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item>
                <!-- /nuov -->


                <!-- <b-nav-item>
                  <b-dropdown id="dropdown-order" text="filtra per mappa" variant="transparent">
                    <b-dropdown-item class="min-w-200px">
                      <a href="#" v-on:click="filterByScenario($event, 0)">Nessuno</a>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="item in this.scenarios"
                      :key="item.id"
                      class="min-w-200px">
                    >
                      <a href="#" v-on:click="filterByScenario($event, item.id)">
                        {{item.name}}
                        </a>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-nav-item> -->
              </b-nav>
            </div>

            <div v-if="processIdeasCount > 0">

              <!--::Module List/Grid/Map -->
              <div v-if="view == 'list' && phase.phaseStatus" class="row mb-5">
                <div v-for="item in processIdeas" :key="item.id" class="col-xl-12 mb-4 align-items-center">
                  <ListIdeaCard
                      :idea="item"
                      :process="process"
                      :process_id="process_id"
                      :phase_id="phase_id"
                      :phase_status_id="phase.phaseStatus.id"
                      :module_id="id"
                      :module="module"
                      :phase="phase"
                      @incFollower="incrementFollower($event)"
                      @decFollower="decrementFollower($event)"
                  ></ListIdeaCard>
                </div>
              </div>

              <div v-if="view == 'grid' && phase.phaseStatus" class="row gutter-b">
                <div
                    v-for="item in processIdeas"
                    :key="item.id"
                    class="col-12 col-md-6 col-lg-4 col-xl-4"
                >
                  <GridIdeaCard
                      :idea="item"
                      :process_id="process_id"
                      :phase_id="phase_id"
                      :phase_status_id="phase.phaseStatus.id"
                      :module_id="id"
                      :module="module"
                      :phase="phase"
                      @incFollower="incrementFollower($event)"
                      @decFollower="decrementFollower($event)"
                  ></GridIdeaCard>
                </div>
              </div>

              <div v-if="view == 'map' && phase.phaseStatus">
                <MapIdeaBlock
                    :ideas="processIdeas"
                    :process_id="process_id"
                    :phase_id="phase_id"
                    :phase_status_id="phase.phaseStatus.id"
                    :module_id="id"
                    :module="module"
                    :phase="phase"
                    :process="process"
                    @incFollower="incrementFollower($event)"
                    @decFollower="decrementFollower($event)"
                ></MapIdeaBlock>
              </div>
            </div>

            <infinite-loading @infinite="loadMore" spinner="bubbles" v-if="this.currentPage < this.pageCount && (view=='list' || view=='grid') && this.orderField"></infinite-loading>

            <div v-if="processIdeasCount == 0" class="p-10">
              <div class="h3 font-weight-bolder">{{$t($handleContentTypeLabel(moduleContentType) + "LIST.NONCISONOIDEE")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Colonna 3  -->
    <div
        class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between align-items-center"
    >
      <div class="d-flex flex-column mt-5 mr-5 position-fixed">
        <!-- Vote -->
        <!-- <div id="kt_quick_panel_toggle" class="d-flex" v-if="voteOpened">
          <router-link
              v-slot="{ href, navigate }"
              :to="{

              }"
          >
            <a
                :href="href"
                class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
                @click="navigate"
                id="pop-vote"
            >
                <span class="symbol symbol-rounded">
                  <span class="symbol-label font-size-h3 font-weight-bold bg-warning text-white">
                    <i class="fas fa-star text-white pr-0 pb-1"></i>
                  </span>
                </span>
            </a>
          </router-link>
        <b-popover target="pop-vote" triggers="hover">
            <template v-slot:title>{{$t("IDEALIST.TUOIVOTI")}}</template>
          </b-popover>
        </div> -->

        <div class="d-flex flex-column">
          <!-- Helpdesk -->
          <!--
          <div class="d-flex">
            <a
                class="mb-5 btn btn-icon bg-primary mx-auto btn-lg px-2"
                @click.prevent="alertHelp"
                id="pop-help"
            >
              <span class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                  <i class="far fa-life-ring text-white icon-2x pb-2"></i>
                </span>
              </span>
            </a>
            <b-popover target="pop-help" triggers="hover">
              <template v-slot:title>{{$t("IDEALIST.ASSISTENZA")}}</template>
            </b-popover>
          </div>
          -->

          <ProcessMenu :process_id="process_id"
                       :is_process_faq="isFaq"
                       :is_process_guidelines="isGuidelines"
                       :is_process_about="isAbout"
                       :is_process_narration = "isNarration"></ProcessMenu>


          <!--    ! ATTENZIONE: CODICE DA TENERE, COMMENTATO PERCHé BACK END NON ANCORA PRONTO !      -->
          <!-- Jamboard -->
<!--          <div class="d-flex">-->

<!--            <a-->
<!--                href="#"-->
<!--                class="mt-10 mb-5 btn btn-icon bg-warning mx-auto d-flex btn-lg px-2"-->
<!--                @click.prevent="alertForm"-->
<!--                id="pop-form"-->
<!--            >-->
<!--              <span class="symbol symbol-rounded">-->
<!--                <span class="symbol-label font-size-h3 font-weight-bold bg-warning text-white">-->
<!--                <span class="svg-icon svg-icon-white svg-icon-2x"-->
<!--                ><svg-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                    xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                    width="24px"-->
<!--                    height="24px"-->
<!--                    viewBox="0 0 24 24"-->
<!--                    version="1.1"-->
<!--                >-->
<!--                    <g-->
<!--                        stroke="none"-->
<!--                        stroke-width="1"-->
<!--                        fill="none"-->
<!--                        fill-rule="evenodd"-->
<!--                    >-->
<!--                      <rect x="0" y="0" width="24" height="24" />-->
<!--                      <path-->
<!--                          d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"-->
<!--                          fill="#000000"-->
<!--                          opacity="0.5"-->
<!--                      />-->
<!--                      <path-->
<!--                          d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"-->
<!--                          fill="#000000"-->
<!--                      />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          opacity="0.8"-->
<!--                          x="10"-->
<!--                          y="9"-->
<!--                          width="7"-->
<!--                          height="2"-->
<!--                          rx="1"-->
<!--                      />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          opacity="0.8"-->
<!--                          x="7"-->
<!--                          y="9"-->
<!--                          width="2"-->
<!--                          height="2"-->
<!--                          rx="1"-->
<!--                      />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          opacity="0.8"-->
<!--                          x="7"-->
<!--                          y="13"-->
<!--                          width="2"-->
<!--                          height="2"-->
<!--                          rx="1"-->
<!--                      />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          opacity="0.8"-->
<!--                          x="10"-->
<!--                          y="13"-->
<!--                          width="7"-->
<!--                          height="2"-->
<!--                          rx="1"-->
<!--                      />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          opacity="0.8"-->
<!--                          x="7"-->
<!--                          y="17"-->
<!--                          width="2"-->
<!--                          height="2"-->
<!--                          rx="1"-->
<!--                      />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          opacity="0.8"-->
<!--                          x="10"-->
<!--                          y="17"-->
<!--                          width="7"-->
<!--                          height="2"-->
<!--                          rx="1"-->
<!--                      />-->
<!--                    </g></svg-->
<!--                ></span>-->
<!--                </span>-->
<!--              </span>-->
<!--            </a>-->
<!--            <b-popover target="pop-form" triggers="hover">-->
<!--              <template v-slot:title>{{$t("IDEALIST.JAMBOARD")}}</template>-->
<!--            </b-popover>-->
<!--          </div>-->


          <!-- Meet -->
<!--          <div class="d-flex">-->
<!--            <a-->
<!--                href="#"-->
<!--                class="mb-5 btn btn-icon bg-warning mx-auto d-flex btn-lg px-2"-->
<!--                @click.prevent="alertMeet"-->
<!--                id="pop-meet"-->
<!--            >-->
<!--              <span class="symbol symbol-rounded">-->
<!--                <span class="symbol-label font-size-h3 font-weight-bold bg-warning text-white">-->
<!--                <span class="svg-icon svg-icon-white svg-icon-2x"-->
<!--                ><svg-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                    xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                    width="24px"-->
<!--                    height="24px"-->
<!--                    viewBox="0 0 24 24"-->
<!--                    version="1.1"-->
<!--                >-->
<!--                    <g-->
<!--                        stroke="none"-->
<!--                        stroke-width="1"-->
<!--                        fill="none"-->
<!--                        fill-rule="evenodd"-->
<!--                    >-->
<!--                      <rect x="0" y="0" width="24" height="24" />-->
<!--                      <rect-->
<!--                          fill="#000000"-->
<!--                          x="2"-->
<!--                          y="6"-->
<!--                          width="13"-->
<!--                          height="12"-->
<!--                          rx="2"-->
<!--                      />-->
<!--                      <path-->
<!--                          d="M22,8.4142119 L22,15.5857848 C22,16.1380695 21.5522847,16.5857848 21,16.5857848 C20.7347833,16.5857848 20.4804293,16.4804278 20.2928929,16.2928912 L16.7071064,12.7071013 C16.3165823,12.3165768 16.3165826,11.6834118 16.7071071,11.2928877 L20.2928936,7.70710477 C20.683418,7.31658067 21.316583,7.31658098 21.7071071,7.70710546 C21.8946433,7.89464181 22,8.14899558 22,8.4142119 Z"-->
<!--                          fill="#000000"-->
<!--                          opacity="0.3"-->
<!--                      />-->
<!--                    </g></svg-->
<!--                ></span>-->
<!--                </span>-->
<!--              </span>-->
<!--            </a>-->
<!--            <b-popover target="pop-meet" triggers="hover">-->
<!--              <template v-slot:title>{{$t("IDEALIST.WEBINAR")}}</template>-->
<!--            </b-popover>-->

<!--          </div>-->
<!--    ! /FINE ATTENZIONE: CODICE DA TENERE !      -->


        </div>
      </div>
    </div>

    <!-- <KTQuickPanelManual :process="process"></KTQuickPanelManual>
    <KTQuickPanelManualDesktop :process="process"></KTQuickPanelManualDesktop> -->

    <RegisterOnPublic
        ref="registerOnPublic2"
        :process="process"
        @switchToLogin="manageLoginModal"
        @userRegisteredToProcess="manageUserRegisteredToProcess($event)"
    ></RegisterOnPublic>
    <LoginRegisterProcessOnPublic
        ref="loginRegisterProcessOnPublic"
        :process="process"
        @switchToRegister="manageRegisterModal"
        @userLoggedInAndRegisteredToProcess="manageUserLoggedInAndRegisteredToProcess()"
        @userLoggedIn = "manageUserLoggedIn"
    ></LoginRegisterProcessOnPublic>
    <LoggedRegisterProcessOnPublic
        ref="loggedRegisterProcessOnPublic"
        :process="process"
        @userLoggedRegisteredToProcess="manageUserLoggedRegisteredToProcess($event)"
    ></LoggedRegisterProcessOnPublic>

    <!-- Advanced Registration modals -->
    <adv-method-auth
        ref="methodAuth"
    ></adv-method-auth>
    <adv-role-auth
        ref="roleAuth"
        @switchToMobile="manageMobile"
    ></adv-role-auth>
    <adv-mobile-auth
        ref="mobileAuth"
        :process="process"
        @switchToMobileVerify="manageMobileVerify"
        @switchToPasswordRecovery="managePasswordRecovery"
    ></adv-mobile-auth>
    <adv-mobile-verify
        ref="mobileVerify"
        @switchToTaxcode="manageTaxcode"
    ></adv-mobile-verify>
    <adv-taxcode-auth
        ref="taxcodeAuth"
        :process="process"
        :module="module"
        @switchToTaxcodeVerified="manageTaxcodeVerified"
    ></adv-taxcode-auth>
    <adv-auth-taxcode-verified
        ref="taxcodeVerified"
        :process="process"
    ></adv-auth-taxcode-verified>
    <adv-auth-taxcode-not-valid
        ref="taxcodeNotValid"
        :process="process"
    ></adv-auth-taxcode-not-valid>

    <adv-register-on-public
        ref="registerOnPublic"
        :process="process"
        @userRegistered="manageUserRegistered"
    ></adv-register-on-public>
    <adv-login-on-public
        ref="loginOnPublic"
        :process="process"
        @userLoggedIn="manageUserLogged"
    ></adv-login-on-public>
    <!-- END:: Advanced Registration modals -->

    <!-- Recovery password modals -->
    <password-recovery
        ref="passwordRecovery"
        @switchToOTPVerify="manageOTPVerify"
    >
    </password-recovery>
    <password-mobile-verify
        ref="passwordMobileVerify"
        @switchToPasswordChange="managePasswordChange"
    ></password-mobile-verify>
    <password-change
        ref="passwordChange"
    ></password-change>
    <!-- End Recovery password modals -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ListIdeaCard from "@/view/pages/modules/ideas/partials/ListIdeaCard";
import GridIdeaCard from "@/view/pages/modules/ideas/partials/GridIdeaCard";
import MapIdeaBlock from "@/view/pages/modules/ideas/partials/MapIdeaBlock";
import Phaseline from "@/view/layout/common/Phaseline";
// import KTQuickPanelManual from "@/view/layout/extras/offcanvas/QuickPanelManual.vue";
// import KTQuickPanelManualDesktop from "@/view/layout/extras/offcanvas/QuickPanelManualDesktop.vue";
//import Pagination from "@/view/layout/common/Pagination";
// import Swal from "sweetalert2";

import RegisterOnPublic from "@/view/pages/auth/partials/RegisterOnPublic";
import LoginRegisterProcessOnPublic from "@/view/pages/auth/partials/LoginRegisterProcessOnPublic";
import LoggedRegisterProcessOnPublic from "@/view/pages/auth/partials/LoggedRegisterProcessOnPublic";

import { mapGetters } from "vuex";

import { processMixin } from "@/mixins/process";
import { ideaMixin } from "@/mixins/idea";
import { alertMixin } from "@/mixins/alert";
import { matrixLight } from "@/mixins/matrixLight";
//import Preloader from "@/view/content/Preloader";
import Swal from "sweetalert2";
import InfiniteLoading from 'vue-infinite-loading';
import JwtService from "@/core/services/jwt.service";
import moment from "moment";
import {RESEND_OTP_CODE} from "@/core/services/store/auth.module";
import AdvMethodAuth from "@/view/pages/auth/partials/advAuthMethod";
import AdvAuthTaxcodeVerified from "@/view/pages/auth/partials/advAuthTaxcodeVerified";
import AdvAuthTaxcodeNotValid from "@/view/pages/auth/partials/advAuthTaxcodeNotValid";
import AdvMobileVerify from "@/view/pages/auth/partials/advAuthMobileVerify";
import AdvTaxcodeAuth from "@/view/pages/auth/partials/advAuthTaxcode";
import AdvMobileAuth from "@/view/pages/auth/partials/advAuthMobile";
import AdvRoleAuth from "@/view/pages/auth/partials/advAuthRole";
import AdvRegisterOnPublic from "@/view/pages/auth/partials/advRegisterOnPublic";
import AdvLoginOnPublic from "@/view/pages/auth/partials/advLoginOnPublic";

import PasswordRecovery from "@/view/pages/auth/partials/PasswordRecovery";
import PasswordMobileVerify from "@/view/pages/auth/partials/PasswordMobileVerify";
import PasswordChange from "@/view/pages/auth/partials/PasswordChange";

import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";

export default {
  name: "ModuleIdeaList",
  mixins: [processMixin, ideaMixin, alertMixin, matrixLight],
  components: {
    GridIdeaCard,
    ListIdeaCard,
    MapIdeaBlock,
    Phaseline,
    ProcessMenu,
    ProcessMenuMobile,
    //Pagination,
    // KTQuickPanelManual,
    // KTQuickPanelManualDesktop,
    RegisterOnPublic,
    LoginRegisterProcessOnPublic,
    LoggedRegisterProcessOnPublic,
    //Preloader
    InfiniteLoading,
    AdvMethodAuth,
    AdvAuthTaxcodeVerified,
    AdvAuthTaxcodeNotValid,
    AdvMobileVerify,
    AdvTaxcodeAuth,
    AdvMobileAuth,
    AdvRoleAuth,
    AdvRegisterOnPublic,
    AdvLoginOnPublic,

    PasswordMobileVerify,
    PasswordRecovery,
    PasswordChange
  },
  static: {
    visibleItemsPerPageCount: 12
  },
  data() {
    return {
      processVoteIdForVoteType1: false,

      moduleContentType: null,

      followeSet: false,
      followSetValue: 0,
      hasProcessManyPhases: null,
      hasPhaseManyModules: null,

      valueVotes1ForIdeas: 0,
      countVoters1ForIdeas: 0,
      valueVotes4ForIdeas: 0,

      publishVotes: false,
      displayViewMap: false,
      maxVersionsPerContent: 0,
      moduleDateStr: '',
      openDate: null,
      closeDate: null,
      forced_process: (this.$route.query && this.$route.query.forced_process) ? this.$route.query.forced_process : false,
      ideaInPs: false,
      id: this.$route.params.id,
      process_id: this.$route.params.process_id,
      phase_id: this.$route.params.phase_id,
      module: [],
      process: [],
      phase: [],
      processIdeas: [],
      isFaq: null,
      isGuidelines: null,
      isAbout: null,
      isNarration: null,

      processIdeasCount: null,
      currentPage: 1,
      pageCount: 0,
      percentage: 0,
      view: "list",

      orderField: "",
      orderVersus: "",

      // variabile per stile personalizzato su filtro selezionato
      selectedChallengeFilter: 0,
      selectedScenarioFilter: 0, // variabile per stile personalizzato su filtro selezionato
      selectedOrder: null, //#orderby-style

      orders: [
        {
          label: "data",
          field: "createdDate"
        },
        {
          label: "followers",
          field: "countFollowers"
        },
        {
          label: "budget",
          field: "budgetMin"
        }
      ],
      challenges: [],
      scenarios: [],
      processScenarios: [],
      filterChallengeId: 0,
      filterScenarioId: 0,
      keywords: "",
      modulesWithVotes: [],
      voteParams: null,
      moduleVote1IsOpen: false,
      moduleVote4IsOpen: false,
      showAlertVote: false,

      modalRegisterOnPublic: false,

      canAccessModuleAnonymous: true,
    };
  },

  watch: {
    $route() {

      this.$isLoading(true);
      this.id = this.$route.params.id;
      this.phase_id = this.$route.params.phase_id;
      this.phase = this.getCurrentPhase(this.process, this.phase_id);
      this.module = this.getCurrentModule(this.process, this.id);
      this.moduleContentType = this.module?.contentType
      this.challenges = this.module.challenges;
      this.setModulesWithVotes(this.phase);
      this.voteParams = this.getVoteParams(this.module);
      this.getScenarios().then(res => {
        this.scenarios = res.data; // scenari dell'organizzazione (filtro esplora)
      });
      this.processScenarios = this.process.scenarios; //scenari del processo (filtro by scenario)
      this.getIdeas();
      this.ideasInPs();
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "canOperate",
      "getOrganization"
    ]),
    is_anonymous(){
      return (this.currentUser.authorities && this.currentUser.authorities.includes('SCOUT'));
    },
    is_anonymous_on_public() {
      if(this.getOrganization.data) {
        return (this.getOrganization.data.publiclyVisible && this.currentUser.id == 5);
      } else {
        return this.currentUser.id === 5;
      }
    },

    is_email_pending() {      
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.emailRegStatus == 'PENDING')
    },
    is_email_confirmed() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.emailRegStatus == 'COMPLETE')
    },
    is_mobile_pending() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.mobileRegStatus == 'PENDING')
    },
    is_mobile_confirmed() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.mobileRegStatus == 'COMPLETE')
    },
    header_background() {
      let bg_img = '/assets/media/ripartire/header-process.jpg';
      if(window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it'))
        bg_img = '/assets/media/ripartire/header-process-dare.png';
      if(window.location.host.includes('spendiamolinsieme.bipart.it') || window.location.host.includes('stage.spendiamolinsieme.bipart.it'))
        bg_img = '/assets/media/spendiamolinsieme/header-process-si.png';
      return bg_img;
      // return (window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it')) ? '/assets/media/ripartire/header-process-dare.png' : '/assets/media/ripartire/header-process.jpg';

    },
    // voteOpened() {
    //   return (this.moduleVote1IsOpen || this.moduleVote4IsOpen);
    // },
    hasBudget() {
      // return (this.valueVotes4ForIdeas > 0);
      return  false;
    }
  },
  filters: {
    truncate(text, length, clamp){
      clamp = clamp || '...';
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    }
  },
  async created() {
    this.process = await this.getProcess(this.process_id, this.forced_process);
    this.percentage = this.getPercentage(this.process);
    this.phase = this.getCurrentPhase(this.process, this.phase_id);
    this.module = this.getCurrentModule(this.process, this.id);
    this.moduleContentType = this.module?.contentType

    this.hasProcessManyPhases = this.process.countProcessPhases > 1;
    this.hasPhaseManyModules = this.phase.countProcess2Modules > 1;

    this.isFaq = this.isTrue(this.process.faq);
    this.isGuidelines = this.isTrue(this.process.guidelines);
    this.isAbout = this.isTrue(this.process.about);
    this.isNarration = this.isTrue(this.process.narration);

    //  breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.process.title, route: this.hasProcessManyPhases ?  "/process-detail/" + this.process_id : "disabled" },
      // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
      { title: this.phase.title, route: this.hasPhaseManyModules ? `/process-phase/${this.phase_id}/${this.process_id}` : 'disabled'},
      { title: this.module.title },
    ]);

    /*
    const indexVoteType1 = this.phase?.processVotes?.findIndex(x => x?.voteTypes[0]?.id == 1) ?? null;

    if(indexVoteType1 != null && indexVoteType1 >= 0) {
      this.valueVotes1ForIdeas = this.phase?.processVotes[indexVoteType1]?.valueVotes1ForIdeas ?? 0;
      this.countVoters1ForIdeas = this.phase?.processVotes[indexVoteType1]?.countVoters1ForIdeas ?? 0;
    }
    */

    if( this.module && this.module.processVoteIdForVoteType1 && this.module.processVoteIdForVoteType1 > 0) {

      const objModule1 = this.module.processVotes.find(obj => {
        return obj.id === this.module.processVoteIdForVoteType1
      })
      this.valueVotes1ForIdeas = objModule1.valueVotes1ForIdeas ?? 0;
      this.countVoters1ForIdeas = objModule1.countVoters1ForIdeas ?? 0;

      this.processVoteIdForVoteType1 = true;
      this.moduleVote1IsOpen = await this.checkVoteIsOpen(this.module.processVoteIdForVoteType1);
    }

    if(this.module && this.module.processVoteIdForVoteType4 && this.module.processVoteIdForVoteType4 > 0) {

      // const objModule4 = this.module.processVotes.find(obj => {
      //   return obj.id === this.module.processVoteIdForVoteType4
      // })
      // this.valueVotes4ForIdeas = objModule4.valueVotes4ForIdeas ?? 0;

      this.moduleVote4IsOpen = await this.checkVoteIsOpen(this.module.processVoteIdForVoteType4);
    }

    if(this.module) {
      this.publishVotes = this.module.publishVotes ?? false;

      this.maxVersionsPerContent = this.module.maxVersionsPerContent;

      this.openDate = this.module.openDate || null;
      this.closeDate = this.module.closeDate || null;

      if(this.openDate) {
        this.openDate = new Date(this.openDate);
        this.moduleDateStr = moment(this.openDate).format("DD/MM/Y");
        //this.moduleDateStr = this.openDate.getDate()  + "/" + (this.openDate.getMonth()+1) + "/" + this.openDate.getFullYear();
      }

      if(this.closeDate) {
        this.closeDate = new Date(this.closeDate);
        this.moduleDateStr += " - " + moment(this.closeDate).format("DD/MM/Y");
        //this.moduleDateStr += " - " + this.closeDate.getDate()  + "/" + (this.closeDate.getMonth()+1) + "/" + this.closeDate.getFullYear();
      }

      this.canAccessModuleAnonymous = await this.$moduleActionAllowed(this.module.id, this.$getBitCreate);
    }

    this.challenges = this.module.challenges;
    this.setModulesWithVotes(this.phase);
    this.voteParams = this.getVoteParams(this.module);
    this.getScenarios().then(res => {
      this.scenarios = res.data; // scenari dell'organizzazione (filtro esplora)
    });

    this.processScenarios = this.process.scenarios; //scenari del processo (filtro by scenario)

    this.getIdeas().then( () => {
      if(this.processIdeasCount > 2)
        this.view = "grid"
    });

    this.ideasInPs();

    if(this.modulesWithVotes && this.modulesWithVotes.length> 0 && this.modulesWithVotes.some(x=>x == this.id)) {
      this.showAlertVote = true;
    }

  },

  methods: {
    isTrue(prop) {
      return prop && prop != "";
    },

    handleSelectedOrder(value) { // #orderby-style
      this.selectedOrder = value;
    },

    loadMore($state) {
      setTimeout(() => {
        if(this.currentPage < this.pageCount) {

          this.currentPage += 1

          return new Promise((resolve, reject) => {
            let qs = "process2ModuleId.equals=" + this.id + "&size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage;

            if (this.filterScenarioId > 0)
              qs += "&scenarioId.equals=" + this.filterScenarioId;

            if (this.filterChallengeId > 0)
              qs += "&challengeId.equals=" + this.filterChallengeId;

            if (this.keywords) qs += "&title.contains=" + this.keywords;

            if (this.orderField)
              qs += "&sort=" + this.orderField + "," + this.orderVersus;

            //Visualizza solo le idee pubbliche
            qs += "&ideaStatusId.equals=3";

            //Visualizza solo la verisiona pubblicata
            qs += "&versionCurrent.equals=true"

            let endpoint = this.generateUrl('ideas-page', true) + "?locs_=descr&fks_=logo,istatus,auth,isast&cnt_=i2foll,icomm&";
            //generateUrl is done
            ApiService.get(this.$apiResourceOpen, endpoint + qs)
                .then(res => {
                  if(res.data.content != null) {

                    res.data.content.forEach(element => {
                      this.processIdeas.push(element);
                    })

                    this.processIdeasCount = res.data.meta.total;
                    this.pageCount = res.data.meta.pages;
                  }

                  $state.loaded();
                })
                .catch(() => {
                  this.$isLoading(false);
                  reject(0);
                });
          });

        } else {

          $state.loaded();
        }
      }, 200);
    },
    getIdeas() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        let qs = "process2ModuleId.equals=" + this.id + ((this.view == 'map' || !this.orderField) ? "" :  ("&size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage));

        //#nuovo
        if(this.filterScenarioId > 0 && this.filterChallengeId > 0) {
          qs += "&scenarioId.equals=" + this.filterScenarioId + "&challengeId.equals=" + this.filterChallengeId;
        } else {
          if (this.filterScenarioId > 0)
            qs += "&scenarioId.equals=" + this.filterScenarioId;
          if (this.filterChallengeId > 0)
            qs += "&challengeId.equals=" + this.filterChallengeId;
        }
        // /#nuovo

        if (this.keywords) qs += "&title.contains=" + this.keywords;

        if (this.orderField)
          qs += "&sort=" + this.orderField + "," + this.orderVersus;

        //Visualizza solo le idee pubbliche
        qs += "&ideaStatusId.equals=3";

        //Visualizza solo la verisiona pubblicata
        qs += "&versionCurrent.equals=true"

        let endpoint = (this.view == 'map' || !this.orderField) ? this.generateUrl('ideas', true) + "?locs_=descr&fks_=logo,istatus,auth,isast&cnt_=i2foll,icomm&val_=lvv1,lvv4&" : this.generateUrl('ideas-page', true) +  "?locs_=descr&fks_=logo,istatus,auth,isast&cnt_=i2foll,icomm&val_=lvv1,lvv4,vote1,vote4&";
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + qs )
        .then(res => {
          this.processIdeas = (this.view == 'map' || !this.orderField) ? res.data : res.data.content;

          this.displayViewMap = (this.processIdeas.some( el => el.centroid != null ) && this.process?.cities?.length > 0);

          if (!this.orderField && this.view != 'map') {
            this.processIdeas = this.shuffle(this.processIdeas);
          }

          this.processIdeasCount = res?.data?.meta?.total ?? this.processIdeas?.length;
          this.pageCount = res?.data?.meta?.pages ?? 1;

          // if(this.processIdeasCount > 2)
          //   this.view = "grid"

          this.$isLoading(false);
          resolve(1);
        })
        .catch(() => {
          this.$isLoading(false);
          reject(0);
        });
      });
    },

    ideasInPs() {

      return new Promise((resolve, reject) => {

        let qs = "?process2ModuleId.equals=" + this.id;

        //Solo le idee in bozza
        qs += "&ideaStatusId.equals=1";

        //Solo la verisiona 1
        qs += "&versionNumber.equals=1";

        //Solo autore loggato
        qs += "&authorId.equals=" + this.currentUser.id;

        let endpoint = this.generateUrl('ideas/count', false);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + qs )
            .then(res => {              
              if(res && res.data && res.data > 0) {
                this.ideaInPs = true;
              }
            })
            .catch(() => {
              reject(0);
            });
      });
    },

    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        case "first":
          this.currentPage = 1;
          break;
        case "last":
          this.currentPage = this.pageCount;
          break;
        default:
          this.currentPage = value;
      }

      await this.getIdeas();
    },

    getSearchData(event) {
      event.preventDefault();
      this.currentPage = 1;
      this.getIdeas();
    },

    setView(value, event) {
      event.preventDefault();
      this.view = value;
      this.currentPage = 1;
      this.getIdeas();
    },

    orderBy(event, field = "id", versus = "desc") {
      event.preventDefault();
      this.orderField = field;
      this.orderVersus = versus;
      this.currentPage = 1;
      this.getIdeas();
    },

    filterByChallenge(event, id = 0) {
      event.preventDefault();
      this.filterChallengeId = id;
      this.currentPage = 1;
      this.getIdeas();

      this.selectedChallengeFilter = id;
    },

    filterByScenario(event, id = 0) {
      event.preventDefault();
      this.filterScenarioId = id;
      this.currentPage = 1;
      this.getIdeas();

      this.selectedScenarioFilter = id;
    },

    async incrementFollower(value) {

      let endpointCanFollow = await this.$endpointCanFollow(this.id);

      if (endpointCanFollow) {

        if (this.is_anonymous_on_public) {
          // this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
          if (this.module.advancedRegistrationRequiredForFollowing) {
            if (this.checkUserStatus()) {
              this.followerAdd(value, this.currentUser.id);
              this.alertAddFollower();
              this.followeSet = false;
              this.followSetValue = null;
            } else {
              this.followeSet = true;
              this.followSetValue = value;
            }
          } else {
            this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
            this.followeSet = true;
            this.followSetValue = value;
          }
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending && this.is_email_pending) {
          this.alertEmailMobileConfirm();
        }  else if(!this.is_anonymous_on_public && this.is_mobile_pending  && !this.is_email_confirmed) {
          this.alertMobileConfirm();
        } else if (!this.is_anonymous_on_public && this.is_email_pending && !this.is_mobile_confirmed) {
          this.alertEmailConfirm();
        } else if (!this.is_anonymous_on_public && !this.userHasProcess(this.process.id)) {
          this.$refs["loggedRegisterProcessOnPublic"].$refs["modalLoggedRegisterProcessOnPublic"].show();
          this.followeSet = true;
          this.followSetValue = value;
        } else {
          if (this.module.advancedRegistrationRequiredForFollowing) {
            if(this.checkUserStatus()) {
              if (this.can_access_module("follow")) {
                this.followerAdd(value, this.currentUser.id);
                this.alertAddFollower();
                this.followeSet = false;
                this.followSetValue = null;
              } else {
                this.alertUserModuleAccessBitmaskFollow();
              }
            } else {
              this.followeSet = true;
              this.followSetValue = value;
            }
          } else {
            if (this.can_access_module("follow")) {
              this.followerAdd(value, this.currentUser.id);
              this.alertAddFollower();
              this.followeSet = false;
              this.followSetValue = null;
            } else {
              this.alertUserModuleAccessBitmaskFollow();
            }
          }
        }

      } else {

        this.alertCanNotFollow();
      }
    },

    async decrementFollower(value) {

      let endpointCanFollow = await this.$endpointCanFollow(this.id);

      if (endpointCanFollow) {

        if (this.is_anonymous_on_public) {
          //this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
          this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
          this.followeSet = true;
          this.followSetValue = value;
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending && this.is_email_pending) {
          this.alertEmailMobileConfirm();
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending  && !this.is_email_confirmed) {
          this.alertMobileConfirm();
        } else if (!this.is_anonymous_on_public && this.is_email_pending && !this.is_mobile_confirmed) {
          this.alertEmailConfirm();
        } else if (!this.is_anonymous_on_public && !this.userHasProcess(this.process.id)) {
          this.$refs["loggedRegisterProcessOnPublic"].$refs["modalLoggedRegisterProcessOnPublic"].show();
          this.followeSet = true;
          this.followSetValue = value;
        } else {
          if (this.module.advancedRegistrationRequiredForFollowing) {
            if (this.checkUserStatus()) {
              if (this.can_access_module("follow")) {
                this.followerDel(value, this.currentUser.id);
                this.alertRemoveFollower();
                this.followeSet = false;
                this.followSetValue = null;
              } else {
                this.alertUserModuleAccessBitmaskFollow();
              }
            } else {
              this.followeSet = false;
              this.followSetValue = null;
            }
          } else {
            if (this.can_access_module("follow")) {
              this.followerDel(value, this.currentUser.id);
              this.alertRemoveFollower();
              this.followeSet = false;
              this.followSetValue = null;
            } else {
              this.alertUserModuleAccessBitmaskFollow();
            }
          }
        }
      } else {
        this.alertCanNotFollow();
      }
    },

    setModulesWithVotes (phase) {
      this.modulesWithVotes = [];
      for ( let i = 0; i < phase.processVotes.length; i++) {
        //phase.processVotes[i].open &&
        if (! this.modulesWithVotes.includes(phase.processVotes[i].process2ModuleId)) {
          this.modulesWithVotes.push(phase.processVotes[i].process2ModuleId);
        }
      }
    },

    /*
    getVoteParams( mod ) {
      let params = {
        types: [],
        borders:{
          "max": "",
          "min": ""
        }
      };

      for(let i = 0; i < mod.processVotes.length; i++) {
        params.types.push(mod.processVotes[i].voteTypes[0].id);
        if(mod.processVotes[i].voteTypes[0].id == 4) {
          if( mod.processVotes[i].scoreMaxValue !== null) {
            params.borders.max = mod.processVotes[i].scoreMaxValue;
          }

          if( mod.processVotes[i].scoreMinValue !== null) {
            params.borders.min = mod.processVotes[i].scoreMinValue;
          }
        }

      }

      return params;
    },
    */

    async checkVoteIsOpen(voteModuleId) {
      return await new Promise((resolve, reject) => {
        let endpoint = this.generateUrl('/process-votes/' + voteModuleId, false, false);
        ApiService.get(this.$apiResourceOpen + endpoint)
            .then(res => {     
              resolve (res.data.open) ;
            })
            .catch(() => {
              reject(0);
            });
      });
    },

    // Method for advanced registration
    checkUserStatus() {
      let token = JwtService.decodeToken()
      if(this.is_anonymous) {                         // Utente anonimo
        this.$refs["methodAuth"].$refs["modalMethod"].show();
      }
      else if(token.mobileRegStatus == 'BLANK') {     // Utente registrato ma senza cellulare
        this.$refs["roleAuth"].$refs["modalRole"].show();
      }
      else if( token.mobileRegStatus == 'PENDING') {  // Utente registrato ma con cellulare non confermato
        this.$store.dispatch(RESEND_OTP_CODE, this.currentUser.id);
        this.$refs["mobileVerify"].$refs["modalSms"].show();
      }
      else if(!this.module.taxcodeValidationRequiredForAdvancedRegistration) {
        return true;
      }
      else if( token.mobileRegStatus == 'COMPLETE' && !token.taxcodePresent ) { // Utente registrato con cellulare confermato ma senza cf
        this.$refs["taxcodeAuth"].$refs["modalCf"].show();
      }
      else if( token.mobileRegStatus == 'COMPLETE' && token.taxcodePresent ) {
        // Utente registrato con cellulare confermato e cf validato
        // Analizzo se il codice fiscale è valido per questo modulo
        let user_enabled = 0;
        if(token.taxcodeP2MStatus.length > 0) {
          token.taxcodeP2MStatus.forEach( (value) => {
            // if( value.p2mId == this.module.id && value.status == 'PASSED' ) {
            //   user_enabled = true;
            // }
            if( value.p2mId == this.module.id ) {
              if(value.status == 'PASSED') {
                user_enabled = 1;
              } else {
                user_enabled = -1;
              }
            }
          })
        }

        if(user_enabled == 1) {
          return true;
        }
        else if(user_enabled == -1) {
          // Utente non abilitato al voto
          this.$refs["taxcodeNotValid"].$refs["modalTaxcodeNotValid"].show();
        }
        else {
          // Utente non ancora validato sul modulo
          this.$refs["taxcodeAuth"].$refs["modalCf"].show();
        }
      }
      return false;
    },
    manageMobile() {
      this.$refs["mobileAuth"].$refs["modalPhone"].show();
    },
    manageMobileVerify(role) {
      this.user_role = role;
      this.$refs["mobileVerify"].$refs["modalSms"].show();
    },
    manageTaxcode() {
      if(this.module.taxcodeValidationRequiredForAdvancedRegistration) {
        this.$refs["taxcodeAuth"].$refs["modalCf"].show();
      } else if(this.checkUserStatus()) {
        // Messaggio di registrazione effettuata
        this.$refs["taxcodeVerified"].$refs["modalTaxcodeVerified"].show();
        this.manageVoteByFlag();
      }
    },

    manageTaxcodeVerified() {
      this.$refs["taxcodeVerified"].$refs["modalTaxcodeVerified"].show();
      this.manageVoteByFlag();
    },
    manageUserRegistered() {
      this.$refs["roleAuth"].$refs["modalRole"].show();
    },
    manageUserLogged() {
      this.updateLocalIdeaData();
      if(this.checkUserStatus()) {
        this.manageVoteByFlag();
      }
    },
    // End method for advanced registration

    async manageUserLoggedIn() {

      this.alertUserLoggedIn(this.process.title);

      if (this.followeSet && this.followSetValue && this.followSetValue > 0) {

        let qs = "?ideaId.equals=" + this.followSetValue;

        let endpoint = this.generateUrl('idea-followers', false, false) + qs;
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint)
        .then(res => {
          if (res.data.some(x => x?.follower?.id == this.currentUser.id)) {

            this.decrementFollower(this.followSetValue);

          } else {

            this.incrementFollower(this.followSetValue);
          }
        });
      }
    },
    manageUserRegisteredToProcess() {
      this.alertUserRegisteredToProcess(this.process.title);
    },
    manageUserLoggedInAndRegisteredToProcess() {
      this.alertUserLoggedInAndRegisteredToProcess( this.process.title );
    },
    manageUserLoggedRegisteredToProcess() {
      this.alertUserLoggedRegisteredToProcess( this.process.title );

      if (this.followeSet && this.followSetValue && this.followSetValue > 0) {

        let qs = "?ideaId.equals=" + this.followSetValue;

        let endpoint = this.generateUrl('idea-followers', false, false) + qs;
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint)
        .then(res => {
          if (res.data.some(x => x?.follower?.id == this.currentUser.id)) {

            this.decrementFollower(this.followSetValue);

          } else {

            this.incrementFollower(this.followSetValue);
          }
        });
      }
    },

    manageRegisterModal() {
      this.$refs["registerOnPublic2"].$refs["modalRegisterOnPublic"].show();
    },
    manageLoginModal() {
      this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
    },
    userHasProcess(value) {
      return this.currentUser.assignedProcesses.includes(value)
    },

    alertInfoProcess(){
      Swal.fire({
        title: this.$t("IDEALIST.INFORMAZIONISULLAFASE"),
        width: 700,
        html: this.phase.description,
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        confirmButtonText: 'Ho capito!',
      });
    },

    shuffle(array) {
      var currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },

    activeVersioning() {
      return (this.module && this.module.activeVersioning);
    },
    canVersioning() {
      return (this.module && this.module.canVersion);
    },

    //MANAGE MATRIX LIGHT
    can_access_module(action = "") {
      return this.$canAccessModule(this.module, action);
    },

    managePasswordRecovery() {
      this.$refs["passwordRecovery"].$refs["modalPasswordRecovery"].show();
    },
    manageOTPVerify() {
      this.$refs["passwordMobileVerify"].$refs["modalPasswordMobileVerify"].show();
    },
    managePasswordChange() {
      this.$refs["passwordChange"].$refs["modalPasswordChange"].show();
    }

  }
};
</script>

<style scoped>

.selectedFilterStyle a {
  position: relative;
  display: inline-block;
}

.selectedFilterStyle a::after {
  position: absolute;
  content: "";
  display: block;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ee765c;
}
</style>
