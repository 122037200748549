var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5 mb-10"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-custom h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-5"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"600px"},attrs:{"center":_vm.myCoordinates,"zoom":_vm.zoom,"options":{
               zoomControl: true,
               mapTypeControl: false,
               scaleControl: false,
               streetViewControl: false,
               rotateControl: false,
               fullscreenControl: false,
               disableDefaultUi: false
             }},on:{"dragend":_vm.handleDrag}},[_c('GmapCluster',{attrs:{"gridSize":30,"zoomOnClick":true}},[_vm._l((_vm.markers),function(marker,index){return _c('GmapMarker',{key:index,attrs:{"title":index.toString(),"position":marker.position,"clickable":true},on:{"click":function($event){return _vm.toggleInfoWindow(marker, index)}}})}),_c('GmapInfoWindow',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen=false}}},[_c('GmapInfoWindowCard',{attrs:{"idea":_vm.infoContent},on:{"addFollower":function($event){return _vm.addFollower($event)},"delFollower":function($event){return _vm.delFollower($event)}}})],1)],2)],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }