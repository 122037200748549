var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block d-md-none mx-6"},[_c('div',{staticClass:"btn-group btn-group-justified",attrs:{"role":"group","aria-label":"..."}},[_c('div',{staticClass:"btn-group mr-2",attrs:{"role":"group"}},[_c('router-link',{attrs:{"to":{
            name: 'processDetail',
            params: { id: this.id }
            }}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-home text-white pr-0 pb-1"})])])],1),(_vm.is_process_about)?_c('div',{staticClass:"btn-group mr-1",attrs:{"role":"group"}},[_c('router-link',{attrs:{"to":{
          name: 'processAbout',
          params: { process_id: this.id }
          }}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-list text-white pr-0 pb-1"})])])],1):_vm._e(),(_vm.is_process_guidelines)?_c('div',{staticClass:"btn-group mr-1",attrs:{"role":"group"}},[_c('router-link',{attrs:{"to":{
          name: 'processGuidelines',
          params: { process_id: this.id }
          }}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-book-open text-white pr-0 pb-1"})])])],1):_vm._e(),(_vm.is_process_narration)?_c('div',{staticClass:"btn-group mr-1",attrs:{"role":"group"}},[_c('router-link',{attrs:{"to":{
          name: 'processNarration',
          params: { process_id: this.id }
          }}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-info-circle text-white pr-0 pb-1"})])])],1):_vm._e(),(_vm.is_process_faq)?_c('div',{staticClass:"btn-group mr-1",attrs:{"role":"group"}},[_c('router-link',{attrs:{"to":{
          name: 'processFaq',
          params: { process_id: this.id }
          }}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-question text-white pr-0 pb-1"})])])],1):_vm._e(),_c('div',{staticClass:"btn-group mr-1",attrs:{"role":"group"}},[_c('a',{staticClass:"btn btn-primary",attrs:{"id":"pop-help"},on:{"click":function($event){$event.preventDefault();return _vm.alertHelp.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-life-ring text-white pr-0 pb-1"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }