import moment from "moment";

// import Vue from "vue";
// import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";
import {SET_CURRENT_PROCESS} from "@/core/services/store/processes.module";
import Vue from "vue";
import i18nService from "../core/services/i18n.service";
// import {SET_ORGANIZATION} from "@/core/services/store/organization.module";

export const processMixin = {
  data() {},
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    uppercase (value) {
      return value.toUpperCase()
    }
  },
  computed: {
    // ...mapGetters([
    //   "getCurrentProcess",
    // ]),
    getProcessLogo() {
      return this.process.logo
          ? this.process.logo.uri
          : "/assets/media/bipart/banner.png";
    }
  },
  methods: {
    getProcess(process_id, forced = false) {
      let cod;
      let cpfound = false;
      let current_process = window.localStorage.getItem("current_process_data");

      if( current_process != 'undefined' && current_process !== null && !forced ) {
        cod = JSON.parse(current_process);
        if( cod.process_id == process_id &&
            cod.data.localizations[0].locale == i18nService.getActiveLanguage() &&
            cod.is_active) {
          cpfound = true;
        }
      }

      if (cpfound) {
        return cod.data;
      } else {
        return this.getProcessByApi(process_id);
      }

    },

    async getProcessByApi(process_id) {
      console.log('Request process from API!!!');
      let p = await new Promise((resolve, reject) => {
        var endpoint = Vue.prototype.generateUrl("processes-expanded-no-groups/" + process_id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
            .then(res => {
              console.log("Processo by API nel componente: ", res.data);
              resolve(res.data);
            })
            .catch(() => {
              reject(0);
            });
      });
      await this.$store.dispatch(SET_CURRENT_PROCESS, p);
      return p;
    },

    getPercentage(p) {
      let d0 = moment(new Date()).format("YYYYMMDD");
      let d1 = moment(p.startDate).format("YYYYMMDD");
      let d2 = moment(p.endDate).format("YYYYMMDD");

      if (d0 > d2) {
        return 100;
      }
      if (d0 < d1) {
        return 0;
      }

      let range = d2 - d1;
      let fromnow = moment(new Date()).format("YYYYMMDD") - d1;
      let perc = Math.round((fromnow * 100) / range);
      return perc;
    }
  }
};
