import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export const ideaMixin = {
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  created() {},
  data() {},
  methods: {
    getContentSubtypes(lang = 'it', moduleContentType = null) {
      return new Promise((resolve, reject) => {

        let filter = moduleContentType === null ? "" : moduleContentType.code;

        // var endpoint = this.generateUrl(`content-subtypes?contentTypeCode.equals=${filter}`, false);
        var endpoint = this.generateUrl(`content-subtypes/locs/${lang}?contentTypeCode.equals=${filter}`, false);

        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint)
            .then(res => {
              
              resolve(res);
            })
            .catch(() => {
              // .catch(({ response }) => {
              
              // context.commit(SET_ERROR, [response.data.error]);
              reject(0);
            });
      });
    },
    getCurrentPhase(process, phase_id) {
      let currentPhase;
      for (let phases of process.processPhases) {
        if (phases.id == phase_id) {
          currentPhase = phases;
          break;
        }
      }
      return currentPhase;
    },

    getCurrentModule(process, module_id) {
      let currentModule;
      for (let module of process.process2Modules) {
        if (module.id == module_id) {
          currentModule = module;
          break;
        }
      }
      return currentModule;
    },

    getCurrentIdea(idea_id) {
      return new Promise((resolve, reject) => {

        var endpoint = this.generateUrl('ideas-expanded/' + idea_id, false);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint)
            .then(res => {
              
              resolve(res);
            })
            .catch(() => {
              // .catch(({ response }) => {
              
              // context.commit(SET_ERROR, [response.data.error]);
              reject(0);
            });
      });
    },

    getLanguages() {
      return this.getOrganization.data.languages;
      //return this.languages = this.getOrganization.data.languages;
      /*return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('languages/flat', false);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
            .then(res => {
              resolve(res);
            })
            .catch(() => {
              reject(0);
            });
      });*/
    },

    // resourcesManager(resources) {
    //   let basket;
    //
    //   for (let r of resources) {
    //     switch (r.resourceType.id) {
    //       case 1:
    //       case 2: // Documenti
    //         basket = this.attaches;
    //         break;
    //       case 3: // Immagini
    //         if (r.imageCategory && r.imageCategory.id == 2) {
    //           basket = this.logo;
    //         } else {
    //           basket = this.images;
    //         }
    //         break;
    //       case 5: // Video
    //         basket = this.video;
    //         break;
    //     }
    //
    //     basket.push({
    //       id: r.id,
    //       name: r.originalFilename,
    //       uri: r.uri
    //     });
    //   }
    //
    
    // },


    followerAdd(idea_id, user_id) {
      let incrementData = {
        ideaId: idea_id,
        follower: {
          id: user_id
        }
      };

      return new Promise((resolve, reject) => {

        var endpoint = this.generateUrl('/idea-followers', false, false);
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, incrementData)
          .then(res => {
            if(this.idea && this.idea?.idea2Followers) {

              this.idea.idea2Followers.push(res.data);

            } else if(this.processIdeas) {

              let index = this.processIdeas.findIndex(x => x.id == idea_id);

              if(this.processIdeas[index]) {
                this.processIdeas[index].countFollowers += 1;
                this.processIdeas[index].followedByAuthUser = true;
              }

            } else if(this.phaseIdeas) {

              let index = this.phaseIdeas.findIndex(x => x.id == idea_id);

              if(this.phaseIdeas[index]) {
                this.phaseIdeas[index].countFollowers += 1;
                this.phaseIdeas[index].followedByAuthUser = true;
              }
            }
          })
          .catch(() => {
            
            reject(0);
          });
      });
    },

    followerDel(idea_id, user_id) {
      let decrementData = {
        ideaId: idea_id,
        follower: {
          id: user_id
        },
        applied: false
      };

      return new Promise((resolve, reject) => {
        //generateUrl is done
        ApiService.put(this.$apiResourceOpen + "/idea-followers", decrementData)
          .then(res => {

            if(this.idea && this.idea?.idea2Followers) {

              for (let i = 0; i < this.idea.idea2Followers.length; i++) {
                if (this.idea.idea2Followers[i].id === res.data.id) {
                  this.idea.idea2Followers.splice(i, 1);
                }
              }

            } else if(this.processIdeas) {

              let index = this.processIdeas.findIndex(x => x.id == idea_id);

              if(this.processIdeas[index]) {
                this.processIdeas[index].countFollowers -= 1;
                this.processIdeas[index].followedByAuthUser = false;
              }

            }  else if(this.phaseIdeas) {

              let index = this.phaseIdeas.findIndex(x => x.id == idea_id);

              if(this.phaseIdeas[index]) {
                this.phaseIdeas[index].countFollowers -= 1;
                this.phaseIdeas[index].followedByAuthUser = false;
              }
            }
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    getChallenges() {
      return new Promise((resolve, reject) => {

        var endpoint = this.generateUrl('challenges', true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            
            
            console.dir(res.data);

            // res.data.each( (obj) => {
            //   if (obj.hasOwnProperty("id")){
            
            //   }
            // })
            resolve(res);
          })
          .catch(() => {
            // .catch(({ response }) => {
            
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },

    getScenarios() {
      return new Promise((resolve, reject) => {

        var endpoint = this.generateUrl('scenarios', true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            
            console.dir(res.data);
            
            resolve(res);
          })
          .catch(() => {
            // .catch(({ response }) => {
            
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },

    getVoteParams( mod ) {
      let params = {
        types: [],
        maxVote1: 0,
        borders:{
          "max": "",
          "min": ""
        }
      };

      if(mod && mod.processVotes && mod.processVotes.length > 0) {
        for (let i = 0; i < mod.processVotes.length; i++) {

          if(mod.processVotes[i].voteTypes && mod.processVotes[i].voteTypes.length > 0 && mod.processVotes[i].open) {

            params.types.push(mod.processVotes[i].voteTypes[0].id);
            if (mod.processVotes[i].voteTypes[0].id == 4) {

              if (mod.processVotes[i].scoreMaxValue !== null) {
                params.borders.max = mod.processVotes[i].scoreMaxValue;
              }

              if (mod.processVotes[i].scoreMinValue !== null) {
                params.borders.min = mod.processVotes[i].scoreMinValue;
              }
            }
            if(mod.processVotes[i].voteTypes[0].id == 1) {
              params.maxVote1 = mod.processVotes[i].maxPreferencesPerVoter;
            }
          }
        }
      }

      return params;
    }
  }
};
