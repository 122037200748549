<template>
  <b-modal centered
      id="modalLoggedRegisterProcessOnPublic"
      ref="modalLoggedRegisterProcessOnPublic"
      size="lg"
      hide-header
      hide-footer
  >
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <img
            :src="
            getOrganization.data
              ? getOrganization.data.logo1Uri
              : '/assets/media/bipart/logo-bipart-brand.svg'
          "
            class="h-60px"
            alt="Logo"
        />
        <div class="mt-5">
          <h2 class="text-primary">{{$t("LOGGEDREGISTERPROCESSONPUBLIC.ISCRIVITIPROCESSO")}}</h2>
          <h1>{{$t("LOGGEDREGISTERPROCESSONPUBLIC.PARTECIPAPROCESSO")}}</h1>
          <div class="text-muted font-weight-bold">
            <p class="lead">
              {{$t("LOGGEDREGISTERPROCESSONPUBLIC.PROPOSTE")}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7  border-left px-5">
        <h2 class="text-primary">{{$t("LOGGEDREGISTERPROCESSONPUBLIC.CONFERMA")}}</h2>
        <h3>{{ this.process.title }}</h3>

        <div v-if="this.process.guidelines"
             class="form-group d-flex flex-wrap justify-content-between align-items-center pt-2 pb-2">
          <input type="checkbox" name="process_guidelines" v-model="process_guidelines" />
          <span v-html='$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA1")'></span>
          <a :href="'/process-guidelines/' + process.id"
             target="_blank">
            {{$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA2")}}</a>
        </div>

        <b-button
            class="btn btn-lg btn-default text-uppercase font-weight-bolder"
            @click="close"
        >{{$t("LOGGEDREGISTERPROCESSONPUBLIC.CHIUDI")}}</b-button
        >
        <b-button
            :disabled='isDisabled'
            class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
            @click.prevent="conferma"
            id="register_to_process_submit"
            ref="register_to_process_submit"
        >{{$t("LOGGEDREGISTERPROCESSONPUBLIC.CONFERMA")}}
        </b-button>

      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { processMixin } from "@/mixins/process";
import {commonMixin} from "@/mixins/common";

import Swal from "sweetalert2";
import {REFRESH_TOKEN} from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "LoggedRegisterProcessOnPublic",
  props: {
    process: process
  },
  mixins: [processMixin, commonMixin],
  data() {
    return {
      process_guidelines: false
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "getOrganization"]),
    isDisabled: function(){
      if( this.process.guidelines && this.process.guidelines != "") {
        return !this.process_guidelines;
      } else {
        return false;
      }
    }
  },
  methods: {
    close() {
      this.$refs["modalLoggedRegisterProcessOnPublic"].hide();
    },

    async conferma() {

      // set spinner to submit button
      const submitButton = this.$refs["register_to_process_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let registerToProcess = {
        organizationDomain: this.$currentDomain,
        processId: this.process.id,
        participationPath: '/process-detail/' + this.process.id,
        locale: i18nService.getActiveLanguage(),
        // groupId: null,
      };

      // Registro l'utente al processo
      ApiService.put(this.$apiResource + "/users/" + this.currentUser.id + "/associate-registered-user-to-process", registerToProcess)
          .then( response => {
            console.log('Risposta dalla promise register in then: ', response);
            if (response.status == 200) {
              console.log('Aggiorno il token');
              this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
              // Swal.fire('Success', 'Autenticazione riuscita e utente associato al processo', 'success')
              console.log('Chiudo la modale');
              this.$refs["modalLoggedRegisterProcessOnPublic"].hide();
              console.log('emit: userLoggedRegisteredToProcess');
              this.$emit("userLoggedRegisteredToProcess");
            } else {
              Swal.fire(this.$t("LOGGEDREGISTERPROCESSONPUBLIC.TITLE1"), this.$t("LOGGEDREGISTERPROCESSONPUBLIC.ERR1"), 'error')

            }
          })
          .catch( err => {
            this.manageAxiosErrors(err);
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          });
    }
  }
};
</script>

<style scoped></style>
