<template>
  <b-modal centered
    id="modalLoginRegisterProcessOnPublic"
    ref="modalLoginRegisterProcessOnPublic"
    size="lg"
    hide-header
    hide-footer
  >
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10 text-md-center mt-md-24">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-3 font-weight-bolder">{{$t("ADVAUTHMETHOD.PARTECIPAPROCESSO")}}</p>

        <div class="my-0">
          <div :style="'display: inline-block; height: 160px; width: 100%; background: url(' + ((getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg') + ') no-repeat center; background-size: contain;'"></div>
<!--          <img-->
<!--              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"-->
<!--              style="max-height: 160px; max-width: 260px"-->
<!--              alt="Logo"-->
<!--            />-->
        </div>
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary d-md-none"></div>
<!--        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>-->
      </div>
      <div class="col-12 col-md-7 border-left px-10">

        <h3 class="text-primary text-center"> {{$t("LOGINREGISTERPROCESSONPUBLIC.ISCRIVITIPROCESSO")}}</h3>
        <h3 class="text-center">{{ this.process.title }}</h3>
        <div style="width: 100%; height: 1px; border-top: 4px dotted lightgrey" class="mt-5"></div>
        <form class="form mt-5" @submit.stop.prevent="onSubmit">
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="text"
              placeholder="username*"
              name="username"
              id="username"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{
                invalid: $v.form.username.$dirty && $v.form.username.$invalid
              }"
            />
            <template
              v-if="$v.form.username.$dirty && $v.form.username.$invalid"
            >
              <span v-if="!$v.form.username.required" class="error pl-5"
                >{{$t("LOGINREGISTERPROCESSONPUBLIC.USERNAMENONESISTE")}}</span
              >
            </template>
          </div>
          <div class="form-group mb-5">
            <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="password"
              placeholder="Password*"
              name="password"
              id="password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="username-live-feedback"
              autocomplete="off"
              :class="{
                invalid: $v.form.password.$dirty && $v.form.password.$invalid
              }"
            />
            <template
              v-if="$v.form.password.$dirty && $v.form.password.$invalid"
            >
              <span v-if="!$v.form.password.required" class="error pl-5"
                >{{$t("LOGINREGISTERPROCESSONPUBLIC.PASSWORDERRATA")}}</span
              >
            </template>
          </div>

          <div
              v-if="this.process.guidelines"
              class="form-group d-flex flex-wrap justify-content-between align-items-center pt-2 pb-2">
              <input type="checkbox" name="process_guidelines" v-model="process_guidelines" />
              <span v-html='$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA1")'></span>
            <a :href="'/process-guidelines/' + this.process.id"
               target="_blank">
              {{$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA2")}}</a>
          </div>

          <div class="form-group d-flex flex-wrap">
            <b-button
              class="btn btn-lg btn-default text-uppercase font-weight-bolder"
              @click="close"
              >{{$t("LOGINREGISTERPROCESSONPUBLIC.CHIUDI")}}</b-button
            >
            <b-button
              :disabled="isDisabled"
              class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
              @click.prevent="loginRegisterToProcess"
              id="login_register_to_process_submit"
              ref="login_register_to_process_submit"
              >{{$t("LOGINREGISTERPROCESSONPUBLIC.CONFERMA")}}
            </b-button>
          </div>
        </form>
        <div style="width: 100%; height: 1px; border-top: 4px dotted lightgrey" class="my-5"></div>

        <div class="" v-if="organizationRegAllowed">
          <p class="mb-2 text-primary font-weight-bold h5">{{$t("LOGINREGISTERPROCESSONPUBLIC.NONFAIPARTE")}}
<!--            {{ getOrganization.data.name }}?-->
          </p>
          <p class="mt-0 mb-0 font-weight-bold">{{$t("LOGINREGISTERPROCESSONPUBLIC.NUOVOACCOUNT")}}</p>
          <b-button
              class="mt-5 mb-5 btn btn-lg btn-primary text-uppercase font-weight-bolder w-100"
              @click.prevent="switchToRegister"
          >{{$t("LOGINREGISTERPROCESSONPUBLIC.REGISTRATI")}}
          </b-button>
<!--          <a-->
<!--            href="#"-->
<!--            @click.prevent="switchToRegister"-->
<!--            class="text-primary font-weight-bolder"-->
<!--            >{{$t("LOGINREGISTERPROCESSONPUBLIC.REGISTRATI")}}</a-->
<!--          >-->
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { processMixin } from "@/mixins/process";
import { commonMixin } from "@/mixins/common";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  LOGIN,
  REFRESH_TOKEN,
  SET_HEADER_TOKEN
} from "@/core/services/store/auth.module";
import jwt_decode from "jwt-decode";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "LoginRegisterProcessOnPublic",
  props: {
    process: process
  },
  mixins: [validationMixin, processMixin, commonMixin],
  data() {
    return {
      process_guidelines: false,
      form: {
        username: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "getOrganization"]),
    organizationRegAllowed(){
      return (this.currentUser && this.currentUser.selectedOrganizationRegAllowed);
    },
    isDisabled: function(){
      if( this.process.guidelines && this.process.guidelines != "") {
        return !this.process_guidelines || !this.form.username || !this.form.password;
      } else {
        return false;
      }
    }
  },
  methods: {
    close() {
      this.$refs["modalLoginRegisterProcessOnPublic"].hide();
    },
    loginRegisterToProcess() {
      this.onSubmit();
    },
    switchToRegister() {
      this.$refs["modalLoginRegisterProcessOnPublic"].hide();
      this.$emit("switchToRegister");
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        username: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        Swal.fire(this.$t("LOGINREGISTERPROCESSONPUBLIC.TITLE2"), this.$t("LOGINREGISTERPROCESSONPUBLIC.MEX1"), "info");
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["login_register_to_process_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let credentials = {
        username: username,
        password: password,
        selectedOrganizationDomain: this.$currentDomain,
        fetchEnabledOnly: true
      };

      let loginResult = await this.$store.dispatch(LOGIN, credentials);
      submitButton.classList.remove("spinner","spinner-light","spinner-right");

      if (loginResult.headers.token == "BadCredentialsException") {
        Swal.fire(this.$t("LOGINREGISTERPROCESSONPUBLIC.TITLE2"), this.$t("LOGINREGISTERPROCESSONPUBLIC.MEX2"), "info");
      } else {
        // Aggiorno il token
        await this.$store.dispatch(REFRESH_TOKEN, loginResult.headers.token);

        // Imposto il nuovo token nell'header
        await this.$store.dispatch(SET_HEADER_TOKEN);

        const userId = jwt_decode(loginResult.headers.token).id;
        const userProcesses = jwt_decode(loginResult.headers.token)
          .assignedProcesses;

        if (!userProcesses.includes(this.process.id)) {
          let registerToProcess = {
            organizationDomain: this.$currentDomain,
            processId: this.process.id,
            participationPath: '/process-detail/' + this.process.id,
            locale: i18nService.getActiveLanguage(),
            // groupId: null,
          };

          // Registro l'utente al processo
          ApiService.put(
            this.$apiResource +
              "/users/" +
              userId +
              "/associate-registered-user-to-process",
            registerToProcess
          )
            .then(response => {
              console.log(
                "Risposta dalla promise register in then: ",
                response
              );
              if (response.status == 200) {
                console.log("Refresh token");
                this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
                console.log("Modal hide");
                this.$refs["modalLoginRegisterProcessOnPublic"].hide();
                console.log('emit event "uLIARTP"');
                this.$emit("userLoggedInAndRegisteredToProcess");
                console.log("event emitted");
              } else {
                Swal.fire(
                    this.$t("LOGINREGISTERPROCESSONPUBLIC.TITLE3"),
                    this.$t("LOGINREGISTERPROCESSONPUBLIC.MEX3"),
                  "error"
                );
              }
            })
            .catch(err => {
              this.manageAxiosErrors(err);
              submitButton.classList.remove("spinner","spinner-light","spinner-right");
            });
        } else {
          this.$refs["modalLoginRegisterProcessOnPublic"].hide();
          this.$emit("userLoggedIn");
        }
      }
    }
  }
};
</script>

<style scoped>
  .error{
    color: #ff0000;
  }
  .invalid{
    border-color: #ff0000;
  }

</style>
