<template>
  <b-modal centered hide-header hide-backdrop hide-footer ok-only no-stacking
  id="modal-verified"
  ref="modalTaxcodeVerified" content-class="shadow" >
    <div class="text-center mt-10">
<!--      <img-->
<!--          :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"-->
<!--          class="h-60px"-->
<!--          alt="Logo"-->
<!--      />-->
<!--      <p class="my-4 font-weight-bolder h4">{{ getOrganization.data.name }}</p>-->
      <h2 class="mt-5">{{$t("ADVAUTHTAXCODEVERIFIED.COMPLIMENTI")}}</h2>
      <p class="lead mb-5">{{$t("ADVAUTHTAXCODEVERIFIED.ADESSOPUOIVOTARE")}}</p>
      <p class="text-dark">{{$t("ADVAUTHTAXCODEVERIFIED.UTENTEABILITATO")}}</p>
      <p class="text-dark">{{$t("ADVAUTHTAXCODEVERIFIED.UTENTEINFO")}} </p>
      <b-button class="btn btn-lg btn-primary text-uppercase font-weight-bolder" @click="close">{{$t("ADVAUTHTAXCODEVERIFIED.CHIUDI")}}</b-button>

    </div>
  </b-modal>

</template>

<script>
// import {mapGetters} from "vuex";

export default {
  name: "advAuthTaxcodeVerify",
  props: ['process'],
  data() {
    return {
    };
  },
  // computed: {
  //   ...mapGetters([
  //     "getOrganization",
  //     "currentUser"
  //   ]),
  // },
  mounted() {},
  methods: {
    close() {
      this.$refs["modalTaxcodeVerified"].hide();
    },
  }
}
</script>

<style scoped>

</style>
