var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom card-stretch border-primary-hover shadow-sm gutter-b zoom ribbon ribbon-clip ribbon-right"},[(_vm.idIdeaRouter)?_c('router-link',{attrs:{"to":{
            name: 'processModuleIdeaDetail',
            query: {
              forced_evaluate: true
            },
            params: {
              id: _vm.idIdeaRouter,
              process_id: _vm.process_id,
              phase_id: _vm.phase_id,
              module_id: _vm.module_id,
              phase_status_id: _vm.phase_status_id
              }
            }}},[(_vm.publishAssessmentsMaster && _vm.ideaSimpleAssessmentStatusId > 1)?_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"top":"210px"}},[_c('span',{staticClass:"ribbon-inner",style:('color:#fff; background-color:' + _vm.ideaSimpleAssessmentStatusColor)}),_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(_vm._s(_vm.assessment))])]):_vm._e()]):_vm._e(),((this.idea.versionNumber && this.idea.versionNumber > 0) && this.module && this.module.activeVersioning)?_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"top":"12px"}},[_c('span',{staticClass:"ribbon-inner bg-primary"}),_c('span',{staticClass:"text-uppercase font-weight-bolder h3"},[_vm._v(_vm._s(this.idea.versionNumber))])]):_vm._e(),_c('div',{staticClass:"card-body ribbon ribbon-top ribbon-ver"},[_c('div',{staticClass:"thumbnail-idea"},[(_vm.idIdeaRouter)?_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":{
        name: 'processModuleIdeaDetail',
        params: {
          id: _vm.idIdeaRouter,
          process_id: _vm.process_id,
          phase_id: _vm.phase_id,
          module_id: _vm.module_id,
          phase_status_id: _vm.phase_status_id
          }
        }}},[_c('img',{staticClass:"portait",attrs:{"src":_vm.idea.logo
              ? _vm.idea.logo.uri
              : '/assets/media/bipart/idea-default.svg',"alt":""}})]):_vm._e()],1),_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.idea.title,50, '...')))]),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.idea.description,90, '...')))]),(_vm.idea.budgetMin)?_c('span',{staticClass:"font-size-h5 font-weight-bold mr-6"},[_vm._v(_vm._s(_vm.$t("PARTIALSIDEE.BUDGET"))+" "+_vm._s(_vm.idea.budgetMin))]):_vm._e()]),_c('div',{staticClass:"card-footer d-flex justify-content-end border-0 pt-0 px-5"},[(this.module && this.module.activeCommenting)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"p-2 mr-1 mt-2"},[_c('i',{staticClass:"fas fa-comment text-primary"}),_vm._v(" "+_vm._s(_vm.idea.countIdeaComments))])]):_vm._e(),(_vm.$canFollow)?_c('div',{staticClass:"d-flex flex-column"},[(_vm.idea.followedByAuthUser)?[_c('button',{staticClass:"btn btn-sm btn-default btn-text-primary mr-2 mt-2",on:{"click":function($event){return _vm.delFollower()}}},[_c('i',{staticClass:"fas fa-heart text-danger"}),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.idea.countFollowers))])])]:[_c('button',{staticClass:"btn btn-sm btn-default btn-text-primary mr-2 mt-2",on:{"click":function($event){return _vm.addFollower()}}},[_c('i',{staticClass:"fas fa-heart text-primary"}),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.idea.countFollowers))])])]],2):(_vm.$activeFollowing)?_c('span',{staticClass:"p-2 mr-1 mt-2"},[_c('i',{staticClass:"fas fa-heart text-danger"}),_vm._v(" "+_vm._s(_vm.idea.countFollowers))]):_vm._e(),(_vm.phase.processVotes.length > 0 && _vm.modulesWithVotes.includes(_vm.module.id))?_c('div',{staticClass:"d-flex flex-column"},[(_vm.idIdeaRouter)?_c('router-link',{staticClass:"mr-2",attrs:{"to":{
            name: 'processModuleIdeaDetail',
              params: {
                id: _vm.idIdeaRouter,
                process_id: _vm.process_id,
                phase_id: _vm.phase_id,
                module_id: _vm.module_id,
                phase_status_id: _vm.phase_status_id
                }
            }}},[_c('span',{staticClass:"mt-1 btn btn-primary btn-block font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VOTA")))])]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column"},[(_vm.idIdeaRouter)?_c('router-link',{staticClass:"mr-2",attrs:{"to":{
            name: 'processModuleIdeaDetail',
              params: {
                id: _vm.idIdeaRouter,
                process_id: _vm.process_id,
                phase_id: _vm.phase_id,
                module_id: _vm.module_id,
                phase_status_id: _vm.phase_status_id
                }
            }}},[_c('span',{staticClass:"mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VISUALIZZA")))])]):_vm._e()],1),(_vm.votedIdeaByUser)?_c('div',{staticClass:"d-flex flex-column"},[_vm._m(0)]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn btn-success"},[_c('i',{staticClass:"fa fa-star text-white pr-0 pb-1"})])
}]

export { render, staticRenderFns }