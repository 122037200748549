<template>
  <b-modal centered  hide-backdrop hide-footer ok-only no-stacking
      id="modal-login-on-public"
      ref="modalLoginOnPublic"
      size="lg"
      content-class="shadow" >
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t('ADVLOGINONPUBLIC.PARTECIPAPROCESSO')}}</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
        <h2 class="text-primary">{{$t('ADVLOGINONPUBLIC.PARTECIPA')}}</h2>
        <h3>{{ this.process.title }}</h3>
        <form class="form mt-5" @submit.stop.prevent="onSubmit">
          <div class="form-group mb-5">
            <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="text"
                v-bind:placeholder="$t('ADVLOGINONPUBLIC.PH1')"
                name="username"
                id="username"
                v-model="$v.form.username.$model"
                :state="validateState('username')"
                aria-describedby="username-live-feedback"
                autocomplete="off"
                :class="{
                invalid: $v.form.username.$dirty && $v.form.username.$invalid
              }"
            />
            <template
                v-if="$v.form.username.$dirty && $v.form.username.$invalid"
            >
              <span v-if="!$v.form.username.required" class="error pl-5"
              >{{$t("ADVLOGINONPUBLIC.USERNONESISTE")}}</span
              >
            </template>
          </div>
          <div class="form-group mb-5">
            <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="password"
                v-bind:placeholder="$t('ADVLOGINONPUBLIC.PH2')"
                name="password"
                id="password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="username-live-feedback"
                autocomplete="off"
                :class="{
                invalid: $v.form.password.$dirty && $v.form.password.$invalid
              }"
            />
            <template
                v-if="$v.form.password.$dirty && $v.form.password.$invalid"
            >
              <span v-if="!$v.form.password.required" class="error pl-5"
              >{{$t("ADVLOGINONPUBLIC.PASSWORDERRATA")}}</span
              >
            </template>
          </div>

          <div class="form-group d-flex flex-wrap">
            <b-button
                class="btn btn-lg btn-default text-uppercase font-weight-bolder"
                v-b-modal.modal-method
            >indietro</b-button
            >
            <b-button
                class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
                @click.prevent="loginRegisterToProcess"
                id="login_register_to_process_submit"
                ref="login_register_to_process_submit"
            >entra
            </b-button>
          </div>

          <p v-html='$t("ADVLOGINONPUBLIC.SUPPORT")'></p>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { processMixin } from "@/mixins/process";
import { commonMixin } from "@/mixins/common";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { LOGIN, REFRESH_TOKEN, SET_HEADER_TOKEN } from "@/core/services/store/auth.module";
// import jwt_decode from "jwt-decode";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "advLoginOnPublic",
  props: {
    process: process
  },
  mixins: [validationMixin, processMixin, commonMixin],
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "getOrganization"])
  },
  methods: {
    close() {
      this.$refs["modalLoginOnPublic"].hide();
    },
    loginRegisterToProcess() {
      this.onSubmit();
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        username: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        Swal.fire(
          this.$t("ADVLOGINONPUBLIC.ALERT"),
          this.$t("ADVLOGINONPUBLIC.ALERTINFO"),
          "info"
        );
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["login_register_to_process_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let credentials = {
        username: username,
        password: password,
        selectedOrganizationDomain: this.$currentDomain,
        fetchEnabledOnly: true
      };

      let loginResult = await this.$store.dispatch(LOGIN, credentials);
      // submitButton.classList.remove("spinner","spinner-light","spinner-right");

      if (loginResult.headers.token == "BadCredentialsException") {
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        Swal.fire(this.$t('MESSAGEAUTH.ATTENZIONE'), this.$t('MESSAGEAUTH.1'), "info");
      } else {
        // Aggiorno il token
        await this.$store.dispatch(REFRESH_TOKEN, loginResult.headers.token);
        await this.$store.dispatch(SET_HEADER_TOKEN);
        /*
        // Verifico se è necessario associare l'utente al processo
        const userId = jwt_decode(loginResult.headers.token).id;
        const userProcesses = jwt_decode(loginResult.headers.token).assignedProcesses;
        */

        let userAssignedToProcess = true;

        /*
        if (!userProcesses.includes(this.process.id)) {
          userAssignedToProcess = await this.userAssignToProcess(userId);
        }
        */

        submitButton.classList.remove("spinner","spinner-light","spinner-right");

        if( userAssignedToProcess ) {
          this.$emit("userLoggedIn");
          this.$refs["modalLoginOnPublic"].hide();
        } else {
          Swal.fire(
              // this.$t("ADVREGISTERONPUBLIC.ALERT2TITLE"), // #key_loc
              // this.$t("ADVREGISTERONPUBLIC.ALERT2"),  // #key_loc
            "error"
          );
        }
      }
    },

    async userAssignToProcess(userId) {
      let registerToProcess = {
        organizationDomain: this.$currentDomain,
        processId: this.process.id,
        participationPath: '/process-detail/' + this.process.id,
        locale: i18nService.getActiveLanguage(),
        // groupId: null,

      };

      // Imposto il nuovo token nell'header
      await this.$store.dispatch(SET_HEADER_TOKEN);

      // Registro l'utente al processo
      //generateUrl is done
      ApiService.put(this.$apiResource + "/users/" + userId + "/associate-registered-user-to-process", registerToProcess)
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
            this.$store.dispatch(SET_HEADER_TOKEN)
            return true;
          } else {
            return false;
          }
        })
        .catch(err => {
          this.manageAxiosErrors(err);
          return false;
        });
    }
  }
};
</script>

<style scoped>
.error{
  color: #ff0000;
}
.invalid{
  border-color: #ff0000;
}
</style>
