<template>
  <b-modal  centered
  id="modal-cf"
  ref="modalCf"
  size="lg"
  hide-backdrop hide-footer content-class="shadow"
  ok-only no-stacking>
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHTAXCODE.PARTECIPAPROCESSO")}}</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
        <div class="h4 text-primary" v-html='$t("ADVAUTHTAXCODE.VERIFICA1")'></div>
        <p class="mt-5" v-html='$t("ADVAUTHTAXCODE.VERIFICA2")'></p>
        <form class="form mt-5 mb-5">

          <div class="form-group mb-5">
            <div class="input-group mb-2">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  v-bind:placeholder="$t('ADVAUTHTAXCODE.PH1')"
                  name="taxcode"
                  id="taxcode"
                  ref="taxcode"
                  v-model="$v.form.taxcode.$model"
                  :state="validateState('taxcode')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{
                      invalid: $v.form.taxcode.$dirty && $v.form.taxcode.$invalid
                    }"
              />
            </div>
            <template
                v-if="$v.form.taxcode.$dirty && $v.form.taxcode.$invalid"
            >
                  <span v-if="!$v.form.taxcode.required" class="error pt-2 pl-5"
                  >{{$t("ADVAUTHTAXCODE.DIMENTICATOCF")}}</span
                  ><br>
              <span v-if="!$v.form.taxcode.taxcodeIsValid" class="error pt-2 pl-5"
              >{{$t("ADVAUTHTAXCODE.ERRORECF")}}</span
              >
            </template>
          </div>
          <div v-if="taxcodeExist">
            <p class="mb-0 text-danger font-weight-bolder">{{$t("ADVAUTHTAXCODE.CFDIVERSO")}}</p>
            <p><b>{{/*$t("ADVAUTHTAXCODE.VERIFICA")*/ /* #key_loc */ }}</b> {{/*$t("ADVAUTHTAXCODE.CHESIACORRETTO")*/ /* #key_loc */}}</p>.
          </div>
          <div class="form-group d-flex flex-wrap">
            <b-button class="btn btn-lg btn-default text-uppercase font-weight-bolder"  @click="close">{{$t("ADVAUTHTAXCODE.CHIUDI")}}</b-button>
            <b-button
                class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
                @click.prevent="checkTaxcode"
                id="taxcode_submit"
                ref="taxcode_submit"
            >{{$t("ADVAUTHTAXCODE.PROCEDI")}}
            </b-button>

          </div>

        </form>
      </div>
    </div>
  </b-modal>

</template>

<script>
import {validationMixin} from "vuelidate";
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import {
  ASSIGN_TAXCODE_TO_USER,
  REFRESH_TOKEN,
  SET_HEADER_TOKEN
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import {commonMixin} from "@/mixins/common";
import i18nService from "@/core/services/i18n.service";

// const taxcodeRegex = new RegExp('^[A-Z]{6}[\\d]{2}[A-Z][\\d]{2}[A-Z][\\d]{3}[A-Z]$');
const taxcodeRegex = new RegExp('^[A-Za-z]{6}[\\d]{2}[A-Za-z][\\d]{2}[A-Za-z][\\d]{3}[A-Za-z]$');
const taxcodeIsValid = (value) => taxcodeRegex.test(value);

export default {
  name: "advTaxcodeAuth",
  mixins: [validationMixin, commonMixin],
  props: ['process','module'],
  data() {
    return {
      form: {
        taxcode: ""
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
    taxcodeExist() {
      return false;
    }
  },
  mounted() {},
  validations: {
    form: {
      taxcode: {
        required,
        taxcodeIsValid
      }
    },
  },
  methods: {
    close() {
      this.$refs["modalCf"].hide();
    },
    checkTaxcode() {
      this.onSubmit();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["taxcode_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // validazione del numero superata
      this.$refs["taxcode"].classList.add("is-valid");

      //## Chiamata di registrazione del record utente
      // La chiamata è legata ai dati dell'utente che chiama:

      let taxcodeData = {
        userId: this.currentUser.id,
        assignData: {
          organizationDomain: this.$currentDomain,
          processId: this.process.id,
          groupId: null,
          process2ModuleId: this.module.id,
          taxcode: this.$v.form.taxcode.$model,
          canOverride: true,
          locale: i18nService.getActiveLanguage(),
        }
      };
      console.log("taxcode: ", taxcodeData);

      await this.$store
        .dispatch(ASSIGN_TAXCODE_TO_USER, taxcodeData)
        .then(response => {
          // console.log('Risposta dalla promise register in then: ', response);
          if (response.status == 200) {
            this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
            this.$store.dispatch(SET_HEADER_TOKEN);
            this.$emit('switchToTaxcodeVerified');
            this.$refs['modalCf'].hide();

          } else if(response.status == 400) {
            this.manageAxiosErrors(response);
          } else {
            Swal.fire(
                this.$t("ADVAUTHTAXCODE.ALERT1TITLE"),
                this.$t("ADVAUTHTAXCODE.ALERT1") + response.status,
              "info"
            );
          }
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
        .catch(err => {
          this.manageAxiosErrors(err);
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    }
  }
}
</script>

<style scoped>
.error{
  color:#f00;
}
.invalid {
  border-color: #f00;
}

</style>
