import { render, staticRenderFns } from "./advAuthMobile.vue?vue&type=template&id=708269b7&scoped=true"
import script from "./advAuthMobile.vue?vue&type=script&lang=js"
export * from "./advAuthMobile.vue?vue&type=script&lang=js"
import style0 from "./advAuthMobile.vue?vue&type=style&index=0&id=708269b7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "708269b7",
  null
  
)

export default component.exports